export default (theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  margin: {
    marginLeft: 260,
  },
  heading: {
    marginTop: 0,
    fontWeight: 'bold',
    color: '#32305D',
  },
  TextareaAutosize: {
    width: '100%',
  },
  descriptionContainer: {
    padding: '40px 0',
  },
  descriptionBlock: {
    marginBottom: '16px',
  },
  image: {
    borderRadius: '5px',
  },
  uploadInput: {
    display: 'none',
  },
  uploadButtonContainer: {
    marginTop: 16,
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  saveButton: {
    backgroundColor: theme.palette.green.main,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.green.hover,
    },
  },
})
