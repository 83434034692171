import React, { useEffect, useState } from 'react'
import { useFirebase, useFirestore } from 'react-redux-firebase'

function AvatarWithFallback({ avatarUrl, classes, size = 200 }) {
  const firestore = useFirestore()
  const firebase = useFirebase()
  const defaultUserAvatar = require('../static/placeholder-avatar.png').default

  const [imageSrc, setImageSrc] = useState(avatarUrl || defaultUserAvatar)

  useEffect(() => {
    getImageFromFirebaseStorage(avatarUrl).then((resizedUrl) => setImageSrc(resizedUrl))
  }, [avatarUrl])

  const getImageFromFirebaseStorage = async (path) => {
    if (avatarUrl && avatarUrl.includes(`_${size}x${size}`)) return avatarUrl
    if (!path) return defaultUserAvatar
    const i = path.lastIndexOf('/')
    const pathWithoutDomain = path.substring(i + 1)
    const pathParams = pathWithoutDomain.split('?')[0].split('%2F')
    const userId = pathParams[2]
    const jointPathParams = pathParams.join('/')

    const resizedUrl = await firebase
      .storage()
      .ref('/' + jointPathParams + `_${size}x${size}`)
      .getDownloadURL()
    if (resizedUrl) {
      await firestore.collection('users').doc(userId).update({ avatarUrl: resizedUrl })
      return resizedUrl
    }
    return defaultUserAvatar
  }

  return <img className={classes.avatar} src={imageSrc} alt='user avatar' />
}

export default AvatarWithFallback
