import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useForm, Controller, useFieldArray } from 'react-hook-form'
import {
  Button,
  Box,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Container,
  IconButton,
  TextField,
  InputLabel,
  Grid,
  FormHelperText,
  Select,
  MenuItem,
} from '@material-ui/core'
import styles from './EditOrganizationPage.styles'
import {
  Delete as DeleteIcon,
  CloudUpload as CloudUploadIcon,
  ArrowUpward as SaveIcon,
} from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'
import Image from 'material-ui-image'
import { useSnackbar } from 'material-ui-snackbar-provider'
import { useFirestore, useFirebase, useFirestoreConnect, isLoaded } from 'react-redux-firebase'
import { useSelector } from 'react-redux'
import { ORGANIZATION_PATH } from '../../../constants/paths'
import LoadingSpinner from '../../../components/LoadingSpinner'
import { InstantSearch, connectAutoComplete, Configure } from 'react-instantsearch-dom'
import { UserIsAuthenticated } from '../../../utils/router'
import { compose } from 'redux'
import searchClient, { getIndexName } from '../../../utils/algolia'
import { checkImageDimensionAndDisplayInfoMessage } from 'utils/image'
import { getResponsibleAutocompleteComponent } from 'components/CustomAutocomplete'
import { getResizedDownloadUrl } from '../../../utils/image'

const usersIndexName = getIndexName('users')

function EditOrganizationPage() {
  // Relevant parameters
  const { organizationId } = useParams()
  const history = useHistory()
  const firestore = useFirestore()
  const firebase = useFirebase() // We're only using Firebase for its firebase.uploadFiles method.
  const snackbar = useSnackbar()

  useFirestoreConnect([
    {
      collection: 'organizations',
    },
  ])

  const organization = useSelector(
    (state) =>
      state.firestore.data.organizations && state.firestore.data.organizations[organizationId],
  )
  const profile = useSelector((state) => state.firebase.profile)

  const { control, handleSubmit, register, errors, setValue, watch, reset } = useForm({
    defaultValues: {
      name: '',
      category: '',
      description: [],
      contactName: '',
      contactEmail: '',
      status: 'published',
    },
  })

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'description',
  })

  useEffect(() => {
    if (organization) reset(organization)
  }, [organization, reset])

  const [owner, setOwner] = useState({})

  const AutocompleteComponent = getResponsibleAutocompleteComponent({
    id: 'user-autocomplete',
    noOptionsText: 'No matching user found',
    textFieldPlaceholder: 'Organization administrator',
    getOptionLabel: (option) => (typeof option === 'string' ? option : option.email),
    onChange: (event, owner) => {
      if (owner) {
        setOwner({ userId: owner.objectID, email: owner.email })
      } else {
        setOwner({})
      }
    },
    value: owner?.email || '',
    setValueToCurrentRefinement: false,
  })
  const UserAutocomplete = connectAutoComplete(AutocompleteComponent)

  // Files logic
  const [preview, setPreview] = useState({
    logo: '//placehold.it/300?text=Placeholder',
    cover: '//placehold.it/600x300?text=Placeholder',
  })
  const [uploadedCover, setUploadedCover] = useState(false)
  const [uploadedLogo, setUploadedLogo] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    register({ name: 'logo' }, { required: 'Please upload an image' })
    register({ name: 'cover' }, { required: 'Please upload an image' })
  }, [register])

  const handleChange = (e) => {
    if (e.target.name === 'logo') {
      setUploadedLogo(true)
      setValue('logo', e.target.files[0])
    } else {
      setUploadedCover(true)
      setValue('cover', e.target.files[0])
      checkImageDimensionAndDisplayInfoMessage(e.target.files[0], snackbar)
    }
  }

  const logo = watch('logo')
  const cover = watch('cover')

  useEffect(() => {
    if (logo && uploadedLogo && (typeof logo !== 'string' || undefined))
      setPreview((p) => {
        return { ...p, logo: URL.createObjectURL(logo) }
      })
  }, [logo, uploadedLogo])

  useEffect(() => {
    if (cover && uploadedCover && (typeof cover !== 'string' || undefined))
      setPreview((p) => {
        return { ...p, cover: URL.createObjectURL(cover) }
      })
  }, [cover, uploadedCover])

  useEffect(() => {
    // Check if images are already set and if so, set preview, set owner if present & disable validation
    if (organization !== undefined || null) {
      setPreview({
        logo: organization.logoUrl,
        cover: organization.coverUrl,
      })
      setOwner(organization?.owner || {})
      setValue('logo', organization.logoUrl)
      setValue('cover', organization.coverUrl)
    }
  }, [organization, organizationId, setValue, register])

  // Handle submission of the organization form
  const onSubmit = async (data) => {
    setLoading(true)

    const ref =
      organizationId === 'new'
        ? await firestore.collection('organizations').doc().id
        : organizationId

    // If not default cover, let's upload!
    if (uploadedCover) {
      const coverRef = await firebase.uploadFiles(`/uploads/${ref}/`, [data.cover])
      const path = coverRef[0].uploadTaskSnapshot.ref.fullPath

      try {
        data.coverUrl = await getResizedDownloadUrl(firebase, path)
      } catch (e) {
        snackbar.showMessage('Error uploading cover image. Please try again.')
        setLoading(false)
        return
      }

      data.coverFullPath = path
    }

    // If not default logo, let's upload!
    if (uploadedLogo) {
      const logoRef = await firebase.uploadFiles(`/uploads/${ref}/`, [data.logo])
      const path = logoRef[0].uploadTaskSnapshot.ref.fullPath

      try {
        data.logoUrl = await getResizedDownloadUrl(firebase, path)
      } catch (e) {
        snackbar.showMessage('Error uploading logo. Please try again.')
        setLoading(false)
        return
      }

      data.logoFullPath = path
    }

    // Delete local properties
    delete data.cover
    delete data.logo
    if (data.description === undefined) data.description = []

    if (organizationId === 'new') {
      firestore
        .collection('organizations')
        .doc(ref)
        .set({
          ...data,
          owner: owner,
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        })
        .then(() => {
          setLoading(false)
          snackbar.showMessage('Changes successfully saved')
          history.push(ORGANIZATION_PATH(ref))
        })
        .catch((err) => {
          setLoading(false)
          console.error('Error:', err) // eslint-disable-line no-console
          return Promise.reject(err)
        })
    } else {
      firestore
        .collection('organizations')
        .doc(ref)
        .update({
          ...data,
          owner: owner,
          updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        })
        .then(() => {
          setLoading(false)
          snackbar.showMessage('Changes successfully saved')
          history.push(ORGANIZATION_PATH(organizationId))
        })
        .catch((err) => {
          setLoading(false)
          console.error('Error:', err) // eslint-disable-line no-console
          return Promise.reject(err)
        })
    }
  }

  const getError = (error) => {
    switch (error) {
      case 'required':
        return 'This field is required'
      case 'pattern':
        return 'Please input a valid email'
      default:
        return ''
    }
  }

  const useStyles = makeStyles(styles)
  const classes = useStyles()

  if (loading || (organizationId !== 'new' && !isLoaded(organization))) {
    return <LoadingSpinner />
  }

  if (isLoaded(profile)) {
    var privileged =
      profile?.isSuperAdmin ||
      profile?.organizations?.[organizationId]?.volunteerStatus === 'admin' ||
      profile?.organizations?.[organizationId].owner
  }

  // Should be synced with frontend (Consider making this dynamic)
  const CATEGORIES = [
    '🌱 Sustainability & Social Action',
    '🧑‍💼 Consulting',
    '🧘 Body & Mind',
    '🎭 Culture',
    '🌍 Diversity',
    '☕️ Leisure',
    '🌐 Professional',
    '💰 Finance',
    '🙏 Unions',
    '🖥 Technology',
    '🏆 Competition',
    '🏳 Regional',
    'Other',
  ]

  return (
    <>
      <Container
        className={`${classes.root} ${organizationId !== 'new' ? classes.margin : null}`}
        maxWidth='md'
      >
        <Card className={classes.card}>
          <CardHeader
            title={
              <h4 className={classes.heading}>
                {privileged ? 'Organization information' : organization?.name || 'New organization'}
              </h4>
            }
          />
          <form onSubmit={handleSubmit(onSubmit)} className={classes.inputs}>
            <CardContent>
              <Grid container direction='row' justifyContent='flex-start'>
                {privileged && (
                  <Grid item xs={12}>
                    <Controller
                      rules={{ required: 'This field is required' }}
                      as={
                        <TextField
                          fullWidth
                          label='Name'
                          type='text'
                          variant='outlined'
                          error={errors.name !== undefined}
                          helperText={getError(errors?.name?.type)}
                        />
                      }
                      name='name'
                      control={control}
                    />
                  </Grid>
                )}

                {privileged && (
                  <Grid item xs={8} style={{ marginTop: 16 }}>
                    <InputLabel className={classes.inputLabel}>Category</InputLabel>
                    <Controller
                      as={
                        <Select
                          variant='outlined'
                          className={classes.select}
                          MenuProps={{
                            elevation: 2,
                          }}
                        >
                          {CATEGORIES.map((category, key) => (
                            <MenuItem key={key} value={category} className={classes.menuItem}>
                              {category}
                            </MenuItem>
                          ))}
                        </Select>
                      }
                      label='Category'
                      name='category'
                      control={control}
                    />
                  </Grid>
                )}

                <Grid container>
                  <Grid item xs={6}>
                    <h4>Logo</h4>
                    <Image
                      src={preview.logo}
                      className={classes.image}
                      style={{ width: 160, height: 'auto', paddingTop: 250 }}
                      imageStyle={{ width: 160, height: 'auto' }}
                    />
                    <input
                      onChange={handleChange}
                      accept='image/*'
                      className={classes.uploadInput}
                      id='logo'
                      name='logo'
                      type='file'
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <h4>Cover photo</h4>
                    <p>
                      The photo will be shown in a wide 16x9 format. The recommended resolution is
                      1280x720 px.
                    </p>
                    <Image
                      imageStyle={{ objectFit: 'cover' }}
                      style={{ paddingTop: 250 }}
                      aspectRatio={16 / 9}
                      src={preview.cover}
                      className={classes.image}
                    />
                    <input
                      onChange={handleChange}
                      accept='image/*'
                      className={classes.uploadInput}
                      id='cover'
                      name='cover'
                      type='file'
                    />
                  </Grid>
                </Grid>
                {privileged && (
                  <Grid container className={classes.uploadButtonContainer}>
                    <Grid item xs={6}>
                      <label htmlFor='logo'>
                        <Button
                          component='span'
                          variant='contained'
                          color='primary'
                          endIcon={<CloudUploadIcon />}
                        >
                          Upload photo
                        </Button>
                        {errors?.logo && (
                          <FormHelperText error>{errors?.logo?.message}</FormHelperText>
                        )}
                      </label>
                    </Grid>
                    <Grid item xs={6}>
                      <label htmlFor='cover'>
                        <Button
                          component='span'
                          variant='contained'
                          color='primary'
                          endIcon={<CloudUploadIcon />}
                        >
                          Upload photo
                        </Button>
                        {errors?.cover && (
                          <FormHelperText error>{errors?.cover?.message}</FormHelperText>
                        )}
                      </label>
                    </Grid>
                  </Grid>
                )}

                <Grid item xs={12} className={classes.descriptionContainer}>
                  {fields.map((item, index) => (
                    <Grid container key={item.id} spacing={3} className={classes.descriptionBlock}>
                      <Grid item xs>
                        <TextField
                          fullWidth
                          variant='outlined'
                          label='Header'
                          defaultValue={item.header}
                          name={`description[${index}].header`}
                          id='header'
                          error={errors?.description?.[index]?.hasOwnProperty('header')}
                          helperText={getError(errors?.description?.[index]?.header?.type)}
                          inputRef={register({ required: 'This field is required' })}
                        />
                      </Grid>
                      {index > 0 && (
                        <Grid item xs={1}>
                          <IconButton aria-label='Delete' onClick={() => remove(index)}>
                            <DeleteIcon />
                          </IconButton>
                        </Grid>
                      )}
                      <Grid item xs={12}>
                        <TextField
                          variant='outlined'
                          multiline
                          minRows={4}
                          className={classes.TextareaAutosize}
                          defaultValue={item.body}
                          name={`description[${index}].body`}
                          label='Body'
                          id='body'
                          error={errors?.description?.[index]?.hasOwnProperty('body')}
                          helperText={getError(errors?.description?.[index]?.body?.type)}
                          inputRef={register({ required: 'This field is required' })}
                        />
                      </Grid>
                    </Grid>
                  ))}
                  {privileged && (
                    <Box align='center' mt={2}>
                      <Button
                        variant='contained'
                        color='primary'
                        onClick={() => append({ name: 'description' })}
                      >
                        New description block
                      </Button>
                    </Box>
                  )}
                </Grid>

                <Grid container spacing={3}>
                  <Grid item xs={8}>
                    <h3 className={classes.heading}>Contact information</h3>
                    <Controller
                      rules={{
                        required: 'This field is required',
                        pattern:
                          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                      }}
                      as={
                        <TextField
                          label='E-mail address'
                          id='contactEmail'
                          className={classes.textField}
                          variant='outlined'
                          disabled={!privileged}
                          fullWidth
                          error={errors.contactEmail !== undefined}
                          helperText={getError(errors?.contactEmail?.type)}
                        />
                      }
                      name='contactEmail'
                      control={control}
                    />
                  </Grid>

                  <Grid item xs={8}>
                    <Controller
                      rules={{ required: 'This field is required' }}
                      as={
                        <TextField
                          label='Name of the contact person'
                          id='name'
                          className={classes.textField}
                          variant='outlined'
                          disabled={!privileged}
                          fullWidth
                          error={errors.contactName !== undefined}
                          helperText={getError(errors?.name?.type)}
                        />
                      }
                      name='contactName'
                      control={control}
                    />
                  </Grid>

                  {profile?.isSuperAdmin && (
                    <>
                      <Grid item xs={8}>
                        <InputLabel className={classes.inputLabel}>
                          Organization administrator
                        </InputLabel>
                        <InstantSearch searchClient={searchClient} indexName={usersIndexName}>
                          <Configure hitsPerPage={50000} />
                          <UserAutocomplete />
                        </InstantSearch>
                      </Grid>
                      <Grid item xs={8}>
                        <InputLabel className={classes.inputLabel}>Status</InputLabel>
                        <Controller
                          as={
                            <Select
                              variant='outlined'
                              className={classes.select}
                              MenuProps={{
                                elevation: 2,
                              }}
                            >
                              <MenuItem className={classes.menuItem} value='published'>
                                Published
                              </MenuItem>
                              <MenuItem value='deleted' className={classes.menuItem}>
                                Deleted
                              </MenuItem>
                            </Select>
                          }
                          label='Status'
                          name='status'
                          rules={{ required: 'This field is required' }}
                          control={control}
                        />
                      </Grid>
                    </>
                  )}
                </Grid>
              </Grid>
            </CardContent>
            {privileged && (
              <CardActions className={classes.actions}>
                <Button
                  variant='contained'
                  size='large'
                  endIcon={<SaveIcon />}
                  disabled={loading}
                  type='submit'
                  className={classes.saveButton}
                >
                  Save & publish profile
                </Button>
              </CardActions>
            )}
          </form>
        </Card>
      </Container>
    </>
  )
}

export default compose(UserIsAuthenticated(EditOrganizationPage))
