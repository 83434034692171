export const ORGANIZATIONS_PATH = '/organizations'
export const USERS_PATH = '/users'
export const EDIT_ORGANIZATION_PATH = (organization) => `/organizations/${organization}/edit`
export const ORGANIZATION_PATH = (organization) => `/organizations/${organization}`
export const EVENTS_PATH = (organization) => `/organizations/${organization}/events`
export const EVENT_PATH = (organization, event) => `/organizations/${organization}/events/${event}`
export const ROOMBOOKING_PATH = (organization, event, booking) =>
  `/organizations/${organization}/events/${event}/bookings/${booking}`
export const POSTS_PATH = (organization) => `/organizations/${organization}/posts`
export const POST_PATH = (organization, post) => `/organizations/${organization}/posts/${post}`
export const VOLUNTEER_JOBS_PATH = (organization) =>
  `/organizations/${organization}/open-opportunities`
export const VOLUNTEER_JOB_PATH = (organization, volunteerJob) =>
  `/organizations/${organization}/open-opportunities/${volunteerJob}`
export const COLLECTIONS_PATH = (organization) => `/organizations/${organization}/collections`
export const COLLECTION_PATH = (organization, collection) =>
  `/organizations/${organization}/collections/${collection}`
export const VOLUNTEERS_PATH = (organization) => `/organizations/${organization}/volunteers`
export const CHAT_PATH = (organization) => `/organizations/${organization}/chat`
export const LOGIN_PATH = '/login'
