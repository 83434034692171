import React, { useState } from 'react'
import { TextField, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { isLoaded } from 'react-redux-firebase'
import { useSelector } from 'react-redux'

import { ReactComponent as EmailSvg } from '../../static/icons8-email_send.svg'
import SvgIcon from '@material-ui/core/SvgIcon'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'flex-end',
    borderTop: '1px solid #EEEEEE',
    paddingBottom: 20,
    paddingTop: 8,
    flex: '1 0 auto',
  },
  logo: {
    width: 36,
    height: 36,
    margin: '4px 16px',
    borderRadius: 36,
  },
  textFieldContainer: {
    width: '100%',
  },
  textField: {
    width: '100%',
    '& .MuiFilledInput-underline:after': {
      display: 'none',
    },
    '& .MuiFilledInput-underline:before': {
      display: 'none',
    },
    '& .MuiInputBase-root': {
      minHeight: 46,
    },
    '& .MuiFilledInput-root': {
      padding: '13px 18px',
      borderRadius: 5,
      fontSize: 14,
    },
  },
  replyAs: {
    margin: 0,
    marginBottom: 8,
    fontSize: 12,
    color: '#707070',
    display: 'block',
  },
  sendButton: {
    width: 46,
    height: 46,
    minWidth: 46,
    margin: '0px 24px',
    borderRadius: 5,
    padding: 0,
    '&.MuiButton-contained': {
      boxShadow: 'none',
    },
    '& .MuiButton-startIcon': {
      margin: 0,
      marginLeft: 8,
    },
    '& .MuiButton-iconSizeMedium > *:first-child': {
      fontSize: '30px',
    },
  },
  svgIcon: {
    paddingTop: 6,
    paddingLeft: 2,
  },
}))

const SendIcon = (props) => (
  <SvgIcon {...props}>
    <EmailSvg />
  </SvgIcon>
)

const ChatInput = ({ organizationId, onSend }) => {
  const classes = useStyles()
  const [input, setInput] = useState('')

  const organization = useSelector((state) => state.firestore.data.organizations?.[organizationId])

  const profile = useSelector((state) => state.firebase.profile)

  const sendMessage = () => {
    if (input.trim().length === 0) return
    setInput('')
    onSend(input)
  }

  if (!isLoaded(organization)) return <></>

  return (
    <form onSubmit={() => onSend(input)} className={classes.root}>
      <img src={organization.logoUrl} alt={organization.name} className={classes.logo} />
      <div className={classes.textFieldContainer}>
        <Typography className={classes.replyAs}>
          Replying as {profile.name} from {organization.name}
        </Typography>
        <TextField
          multiline
          rowsMax={3}
          variant='filled'
          placeholder='Write message'
          value={input}
          onChange={(x) => setInput(x.target.value)}
          onKeyDown={(ev) => {
            if (ev.key === 'Enter' && !ev.shiftKey) {
              ev.preventDefault()
              sendMessage()
            }
          }}
          className={classes.textField}
        />
      </div>
      <Button
        variant='contained'
        className={classes.sendButton}
        color='primary'
        startIcon={<SendIcon className={classes.svgIcon} />}
        onClick={() => sendMessage()}
      >
        <></> {/* Children required.. */}
      </Button>
    </form>
  )
}

export default ChatInput
