import React from 'react'
import { useFirestoreConnect, isLoaded } from 'react-redux-firebase'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, IconButton } from '@material-ui/core'
import { Close as CloseIcon, Check as CheckIcon } from '@material-ui/icons'

const useStyle = makeStyles((theme) => ({
  root: {
    backgroundColor: '#F5F5F5',
    borderRadius: 5,
    marginBottom: 12,
    paddingRight: 12,
    overflow: 'hidden',
  },
  accepted: {
    backgroundColor: theme.palette.green.main,
    width: 48,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
  },
  text: {
    flexGrow: 1,
    maxWidth: '75%',
    marginLeft: 16,
    fontWeight: 'bold',
    marginTop: 12,
    marginBottom: 12,
  },
  subtext: {
    fontSize: 12,
    color: '#707070',
    fontWeight: 'normal',
  },
  cancel: {
    height: 32,
    width: 32,
    right: 0,
    marginLeft: 'auto',
    margin: 'auto 0',
  },
}))

function CollaboratorListItem({ organizationId, status, remove }) {
  const classes = useStyle()

  const storeCollaboratorOrganizationAs = `collaboratorOrganization${organizationId}`
  useFirestoreConnect([
    {
      collection: 'organizations',
      doc: organizationId,
      storeAs: storeCollaboratorOrganizationAs,
    },
  ])

  const organization = useSelector((state) => state.firestore.data.organizations?.[organizationId])

  let subtext
  switch (status) {
    case 'accepted':
      subtext = 'Invitation accepted.'
      break
    case 'pending':
      subtext = 'An invitation has been sent.'
      break
    default:
      subtext = 'An invitation will be sent when the content is published.'
      break
  }

  return (
    isLoaded(organization) && (
      <Grid container alignItems='stretch' className={classes.root}>
        {status === 'accepted' && (
          <div className={classes.accepted}>
            <CheckIcon />
          </div>
        )}
        <div className={classes.text}>
          {organization?.name}
          <div className={classes.subtext}>{subtext}</div>
        </div>
        <IconButton className={classes.cancel} size='small' onClick={remove}>
          <CloseIcon fontSize='small' />
        </IconButton>
      </Grid>
    )
  )
}

export default CollaboratorListItem
