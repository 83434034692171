import React from 'react'
import { useFirebase } from 'react-redux-firebase'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
import { useSnackbar } from 'material-ui-snackbar-provider'
import LoginForm from '../../components/LoginForm'
import styles from './LoginPage.styles'
import { UserIsNotAuthenticated } from '../../utils/router'
import { compose } from 'redux'

const useStyles = makeStyles(styles)

function LoginPage() {
  const classes = useStyles()
  const firebase = useFirebase()
  const snackbar = useSnackbar()

  const onSubmitFail = (formErrs, dispatch, err) =>
    snackbar.showMessage(formErrs ? 'Form Invalid' : err.message || 'Error')
  const emailLogin = (creds) =>
    firebase.login(creds).catch((err) => snackbar.showMessage(err.message))

  return (
    <div className={classes.root}>
      <Paper className={classes.panel}>
        <LoginForm onSubmit={emailLogin} onSubmitFail={onSubmitFail} />
      </Paper>
    </div>
  )
}

export default compose(UserIsNotAuthenticated(LoginPage))
