import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Container,
  FormHelperText,
  Grid,
  IconButton,
  Switch,
  TextField,
  Typography,
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { useHistory, useParams } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import {
  Backspace,
  CloudUpload as CloudUploadIcon,
  Delete as DeleteIcon,
  EventAvailable as EventAvailableIcon,
  Save as SaveIcon,
} from '@material-ui/icons'
import _ from 'lodash'
import Image from 'material-ui-image'
import { useSnackbar } from 'material-ui-snackbar-provider'
import { Configure, connectAutoComplete, InstantSearch } from 'react-instantsearch-dom'
import { useSelector } from 'react-redux'
import { isLoaded, useFirebase, useFirestore, useFirestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import { checkImageDimensionAndDisplayInfoMessage } from 'utils/image'
import CollaboratorListItem from '../../../../components/CollaboratorListItem'
import LoadingSpinner from '../../../../components/LoadingSpinner'
import PinnedDialog from '../../../../components/PinnedDialog'
import ResponsibleListItem from '../../../../components/ResponsibleListItem'
import { EVENTS_PATH } from '../../../../constants/paths'
import searchClient, { getIndexName } from '../../../../utils/algolia'
import { UserIsAuthenticated } from '../../../../utils/router'
import styles from './EventPage.styles'
import { getResponsibleAutocompleteComponent } from 'components/CustomAutocomplete'
import DateTimePicker from '../../../../components/DateTimePicker'
import { getResizedDownloadUrl } from '../../../../utils/image'
import { onOrganizationFormSubmit } from '../../../../utils/form'

const usersIndexName = getIndexName('users')
const contentIndexName = getIndexName('content')

const EMAIL_PATTERN =
  /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/

function EventPage() {
  // Relevant parameters
  const { organizationId, eventId } = useParams()
  const snackbar = useSnackbar()
  const history = useHistory()
  const firestore = useFirestore()
  const firebase = useFirebase() // We're only using Firebase for its firebase.uploadFiles method.
  const storeAsEvents = `organizationEvents${organizationId}`
  const storeAsOrganization = `organization${organizationId}`
  const volunteersStoreAs = `organization${organizationId}Volunteers`
  const collaboratorsStoreAs = `organziation${organizationId}Collaborators`
  const bookingStoreAs = `bookings${eventId}`

  // Set states
  const [loading, setLoading] = useState(false)
  const [timeBegin, handleTimeBeginChange] = useState(null)
  const [timeEnd, handleTimeEndChange] = useState(null)
  const [pinnedDialogOpen, setPinnedDialogOpen] = useState(false)
  const [pinnedTitle, setPinnedTitle] = useState('')

  // Booking related states
  const [disableLocationInput, setDisableLocationInput] = useState(false)

  // Attach booking listener
  useFirestoreConnect([
    {
      collection: 'organizations',
      doc: organizationId,
      subcollections: [
        {
          collection: 'events',
          doc: eventId,
          subcollections: [
            {
              collection: 'bookings',
              orderBy: [['createdAt', 'desc']],
            },
          ],
        },
      ],
      storeAs: bookingStoreAs,
    },
  ])

  // Attach events listener
  useFirestoreConnect([
    {
      collection: 'organizations',
      doc: organizationId,
      subcollections: [
        {
          collection: 'events',
        },
      ],
      storeAs: storeAsEvents,
    },
  ])

  // Attach organization listener
  useFirestoreConnect([
    {
      collection: 'organizations',
      doc: organizationId,
      storeAs: storeAsOrganization,
    },
  ])

  // Attach volunteers listener
  useFirestoreConnect([
    {
      collection: 'organizations',
      doc: organizationId,
      subcollections: [
        {
          collection: 'volunteers',
          where: ['status', '==', 'volunteer'],
        },
      ],
      storeAs: volunteersStoreAs,
    },
  ])

  // Attach collaborators listener
  useFirestoreConnect([
    {
      collection: 'organizations',
      doc: organizationId,
      subcollections: [
        {
          collection: 'events',
          doc: eventId,
          subcollections: [
            {
              collection: 'collaborators',
            },
          ],
        },
      ],
      storeAs: collaboratorsStoreAs,
    },
  ])

  // Retrieve relevant information from the store
  const event = useSelector((state) => state.firestore.data[storeAsEvents]?.[eventId])
  const organization = useSelector((state) => state.firestore.data[storeAsOrganization])

  const volunteers = useSelector((state) => state.firestore.ordered[volunteersStoreAs])

  const collaborators = useSelector((state) => state.firestore.ordered[collaboratorsStoreAs])

  const profile = useSelector((state) => state.firebase.profile)

  const bookingsStore = useSelector((state) => state.firestore.data[bookingStoreAs])

  const isEventExpired = new Date(event?.timeEnd.seconds * 1000) < new Date()
  const canBePublished = event?.status !== 'published' || isEventExpired

  // Map bookings from an object where the ID is a key to an array which includes the ID
  let bookings = []
  if (bookingsStore) bookings = Object.entries(bookingsStore).map((e) => ({ id: e[0], ...e[1] }))

  if (isLoaded(profile)) {
    var privileged =
      profile?.isSuperAdmin ||
      profile?.organizations?.[organizationId]?.volunteerStatus === 'admin' ||
      profile?.organizations?.[organizationId].owner
  }

  const { control, handleSubmit, register, errors, setValue, watch, reset } = useForm({
    defaultValues: {
      name: '',
      description: '',
      schedule: [],
      location: '',
      isPinned: false,
      responsibleVolunteers: [],
      signupLink: '',
    },
  })

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'schedule',
  })

  const {
    fields: responsibleFields,
    append: appendResponsible,
    remove: removeResponsible,
  } = useFieldArray({
    control,
    name: 'responsibleVolunteers',
  })

  const {
    fields: collaboratorFields,
    append: appendCollaborator,
    remove: removeCollaborator,
  } = useFieldArray({
    control,
    keyName: 'key',
    name: 'collaborators',
  })

  const ResponsibleAutocompleteComponent = getResponsibleAutocompleteComponent({
    id: 'user-autocomplete',
    noOptionsText: 'No matching user found',
    textFieldPlaceholder: 'Select a volunteer',
    onChange: (e, value) => (value?.objectID ? appendResponsible(value.objectID) : null),
    setValueToCurrentRefinement: false,
  })

  const CollaboratorAutocompleteComponent = getResponsibleAutocompleteComponent({
    id: 'collaborator-autocomplete',
    noOptionsText: 'No matching organization found',
    textFieldPlaceholder: 'Select an organization',
    onChange: (e, value) => (value?.objectID ? appendCollaborator({ id: value.objectID }) : null),
    setValueToCurrentRefinement: false,
  })

  const CollaboratorAutocomplete = connectAutoComplete(CollaboratorAutocompleteComponent)
  const ResponsibleAutocomplete = connectAutoComplete(ResponsibleAutocompleteComponent)

  useEffect(() => {
    if (event) reset({ ...event, collaborators: collaborators })
  }, [event, reset, collaborators])

  // Files logic
  const [preview, setPreview] = useState({ photo: '//placehold.it/600x300?text=Placeholder' })
  const [uploadedPhoto, setUploadedPhoto] = useState(false)

  useEffect(() => {
    register({ name: 'photo' }, { required: 'Please upload an image' })
  }, [register])

  const handleChange = (e) => {
    setUploadedPhoto(true)
    setValue('photo', e.target.files[0])
    checkImageDimensionAndDisplayInfoMessage(e.target.files[0], snackbar)
  }

  const photo = watch('photo')

  // If a photo is uploaded set the preview
  useEffect(() => {
    if (photo && uploadedPhoto && (typeof photo !== 'string' || undefined))
      setPreview((p) => {
        return { ...p, photo: URL.createObjectURL(photo) }
      })
  }, [photo, uploadedPhoto])

  useEffect(() => {
    // Check if images are already set and if so, set preview, disable validation, set existing timeBegin and timeEnd & set Organization
    if (event) {
      setPreview({
        photo: event.photoUrl,
      })
      setValue('photo', event.photoUrl)
      handleTimeBeginChange(new Date(event.timeBegin.seconds * 1000))
      handleTimeEndChange(new Date(event.timeEnd.seconds * 1000))
    }
  }, [event, eventId, setValue, register])

  // Make sure that TimeEnd is always after TimeBefore
  useEffect(() => {
    if (timeBegin > timeEnd) handleTimeEndChange(timeBegin)
  }, [timeBegin, timeEnd])

  const updateCollaborators = (newCollaborators, collaborators, ref) => {
    const added = _.difference(_.map(newCollaborators, 'id'), _.map(collaborators, 'id'))
    const removed = _.difference(_.map(collaborators, 'id'), _.map(newCollaborators, 'id'))

    if (added.length === 0 && removed.length === 0) {
      return
    }

    const collectionRef = firestore
      .collection('organizations')
      .doc(organizationId)
      .collection('events')
      .doc(ref)
      .collection('collaborators')

    // Delete removed collaborators
    removed.forEach((id) => {
      collectionRef.doc(id).delete()
    })

    // Add new collaborators
    added.forEach((id) => {
      collectionRef.doc(id).set({ status: 'pending' })
    })
  }

  // Handle submission of the event form
  const saveChanges = async (data, status) => {
    if (!timeBegin || !timeEnd) {
      snackbar.showMessage('Please input valid dates for the event')
      return null
    }

    setLoading(true)

    const ref =
      eventId === 'new'
        ? await firestore.collection('organizations').doc(organizationId).collection('events').doc()
            .id
        : eventId

    // If not default photo, let's upload!
    if (uploadedPhoto) {
      const photoRef = await firebase.uploadFiles(`/uploads/events/${ref}/`, [data.photo])
      const path = photoRef[0].uploadTaskSnapshot.ref.fullPath

      try {
        data.photoUrl = await getResizedDownloadUrl(firebase, path)
      } catch (e) {
        snackbar.showMessage('Error uploading image. Please try again.')
        setLoading(false)
        return
      }

      data.photoId = path
    }

    // Delete local properties
    delete data.photo
    if (data.schedule === undefined) data.schedule = []

    // Update collaborators subcollection and delete from data
    updateCollaborators(data.collaborators, collaborators, ref)
    delete data.collaborators

    if (eventId === 'new') {
      firestore
        .collection('organizations')
        .doc(organizationId)
        .collection('events')
        .doc(ref)
        .set({
          ...data,
          timeBegin: timeBegin,
          timeEnd: timeEnd,
          status,
          organizationId: organizationId,
          organization: {
            name: organization.name,
            logoUrl: organization.logoUrl,
          },
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        })
        .then(() => {
          setLoading(false)
          snackbar.showMessage('Changes successfully saved')
          history.push(EVENTS_PATH(organizationId))
        })
        .catch((err) => {
          setLoading(false)
          console.error('Error:', err) // eslint-disable-line no-console
          return Promise.reject(err)
        })
    } else {
      firestore
        .collection('organizations')
        .doc(organizationId)
        .collection('events')
        .doc(ref)
        .update({
          ...data,
          timeBegin: timeBegin,
          timeEnd: timeEnd,
          status,
          organizationId: organizationId,
          organization: {
            name: organization.name,
            logoUrl: organization.logoUrl,
          },
          updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        })
        .then(() => {
          setLoading(false)
          snackbar.showMessage('Changes successfully saved')
          history.push(EVENTS_PATH(organizationId))
        })
        .catch((err) => {
          setLoading(false)
          console.error('Error:', err) // eslint-disable-line no-console
          return Promise.reject(err)
        })
    }
  }

  // Dialog when pinning event

  const handlePinnedDialogCancel = () => {
    setValue('isPinned', false)
    setPinnedDialogOpen(false)
  }

  const handlePinnedDialogAccept = () => setPinnedDialogOpen(false)

  const getError = (error) => {
    switch (error) {
      case 'required':
        return 'This field is required'
      case 'pattern':
        return 'Please input a time (ex: 12:00)'
      default:
        return ''
    }
  }

  // If we've got an active or pending booking, disable modification of the location field & 'Request room' button
  useEffect(() => {
    if (
      bookings !== undefined &&
      bookings.length > 0 &&
      bookings.filter(
        (booking) =>
          booking.response.status === 'accepted' || booking.response.status === 'pending',
      ).length > 0
    ) {
      setDisableLocationInput(true)
    } else {
      setDisableLocationInput(false)
    }
  }, [bookings])

  const handlePinnedState = ([e]) => {
    if (e.target.checked) {
      firestore
        .collection('organizations')
        .doc(organizationId)
        .get()
        .then(function (doc) {
          if (doc.data()?.pinnedContent && doc.data()?.pinnedContent?.id !== eventId) {
            setPinnedTitle(doc.data().pinnedContent.title)
            setPinnedDialogOpen(true)
          }
        })
    }
    return e.target.checked
  }

  const useStyles = makeStyles(styles)
  const classes = useStyles()

  if (loading || (eventId !== 'new' && !isLoaded(event)) || !isLoaded(organization)) {
    return <LoadingSpinner />
  }

  return (
    <>
      <PinnedDialog
        open={pinnedDialogOpen}
        handleCancel={handlePinnedDialogCancel}
        handleAccept={handlePinnedDialogAccept}
        title={pinnedTitle}
      />
      <Container className={classes.root} maxWidth='md'>
        <Card className={classes.card}>
          <CardHeader
            title={
              <h4 className={classes.heading}>
                {eventId === 'new' ? 'Create event' : 'Edit event'}
              </h4>
            }
          />
          <form className={classes.inputs}>
            <CardContent>
              <Grid container spacing={3} direction='row' justifyContent='flex-start'>
                <Grid item xs={12}>
                  <Controller
                    rules={{ required: 'This field is required' }}
                    as={
                      <TextField
                        fullWidth
                        label='Event title'
                        type='text'
                        variant='outlined'
                        error={errors.name !== undefined}
                        helperText={getError(errors?.name?.type)}
                      />
                    }
                    name='name'
                    control={control}
                  />
                </Grid>

                <Grid item xs={12}>
                  <DateTimePicker
                    label='Start time'
                    timeValue={timeBegin}
                    handleTimeChange={handleTimeBeginChange}
                  />
                </Grid>

                <Grid item xs={12}>
                  <DateTimePicker
                    label='End time'
                    timeValue={timeEnd}
                    handleTimeChange={handleTimeEndChange}
                    minDate={timeBegin}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Controller
                    rules={{ required: 'This field is required' }}
                    as={
                      <TextField
                        fullWidth
                        multiline
                        minRows={6}
                        label='Description'
                        type='text'
                        variant='outlined'
                        error={errors.description !== undefined}
                        helperText={getError(errors?.description?.type)}
                      />
                    }
                    name='description'
                    control={control}
                  />
                </Grid>

                <Grid item xs={12}>
                  <h4 className={classes.heading}>Event schedule (optional)</h4>
                  {fields.map((item, index) => (
                    <Grid container spacing={3} key={item.id}>
                      <Grid item xs={2}>
                        <TextField
                          InputLabelProps={{
                            shrink: true,
                          }}
                          placeholder='__:__'
                          variant='outlined'
                          defaultValue={item.time}
                          fullWidth
                          label='Time'
                          name={`schedule[${index}].time`}
                          id='time'
                          error={errors?.schedule?.[index]?.hasOwnProperty('time')}
                          helperText={getError(errors?.schedule?.[index]?.time?.type)}
                          inputRef={register({
                            required: 'This field is required',
                            pattern: /^\d\d:\d\d$/i,
                          })}
                        />
                      </Grid>
                      <Grid item xs={9}>
                        <TextField
                          variant='outlined'
                          fullWidth
                          defaultValue={item.activity}
                          name={`schedule[${index}].activity`}
                          label='Description'
                          id='activity'
                          error={errors?.schedule?.[index]?.hasOwnProperty('activity')}
                          helperText={getError(errors?.schedule?.[index]?.activity?.type)}
                          inputRef={register({ required: 'This field is required' })}
                        />
                      </Grid>
                      <Grid item xs>
                        <IconButton
                          aria-label='Delete'
                          onClick={() => remove(index)}
                          data-index={index}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  ))}
                  <Box align='center' mt={2}>
                    <Button
                      variant='contained'
                      color='primary'
                      type='button'
                      onClick={() => append({ name: 'description' })}
                    >
                      New schedule item
                    </Button>
                  </Box>
                </Grid>

                <Grid item xs={12}>
                  <h4>Photo</h4>
                  <p>
                    The photo will be shown in a wide 16x9 format. The recommended resolution is
                    1280x720 px.
                  </p>
                  <Image
                    imageStyle={{ objectFit: 'cover' }}
                    src={preview.photo}
                    aspectRatio={16 / 9}
                    className={classes.image}
                  />
                  <input
                    onChange={handleChange}
                    accept='image/*'
                    className={classes.uploadInput}
                    id='photo'
                    name='photo'
                    type='file'
                  />
                  <label htmlFor='photo' className={classes.uploadButtonLabel}>
                    <Button
                      component='span'
                      variant='contained'
                      color='primary'
                      endIcon={<CloudUploadIcon />}
                    >
                      Upload photo
                    </Button>
                    {errors?.photo && (
                      <FormHelperText className={classes.center} error>
                        {errors?.photo?.message}
                      </FormHelperText>
                    )}
                  </label>
                </Grid>

                <Grid item xs={8}>
                  <h4>Location</h4>
                  <Controller
                    rules={{ required: 'This field is required' }}
                    as={
                      <TextField
                        label='Location'
                        id='location'
                        className={classes.textField}
                        variant='outlined'
                        disabled={disableLocationInput}
                        fullWidth
                        error={errors.location !== undefined}
                        helperText={getError(errors?.location?.type)}
                      />
                    }
                    disabled={disableLocationInput}
                    name='location'
                    control={control}
                  />
                  {disableLocationInput && (
                    <FormHelperText>
                      This is field disabled due to a pending or active room booking request. Please
                      cancel your request to edit this field. If your room booking request is
                      accepted, this field will contain your assigned room.
                    </FormHelperText>
                  )}
                </Grid>

                {privileged && (
                  <>
                    <Grid item xs={12}>
                      <h4>Pinned</h4>
                      <p>
                        You can pin one post, event, collection or open opportunity to the top of
                        the page.
                      </p>{' '}
                      <Controller
                        as={<Switch color='primary' />}
                        name='isPinned'
                        onChange={handlePinnedState}
                        control={control}
                      />
                    </Grid>

                    <Grid container item xs={6}>
                      <h4 className={classes.h4}>Responsible</h4>
                      <p>
                        Assign responsibility to a volunteer to give them access to edit this
                        content.
                      </p>
                      <Grid item xs={6} className={classes.inputContainer}>
                        {isLoaded(volunteers) && (
                          <InstantSearch searchClient={searchClient} indexName={usersIndexName}>
                            <Configure
                              filters={
                                !volunteers || volunteers.length === responsibleFields.length
                                  ? 'ObjectID:ReturnNothing'
                                  : volunteers
                                      .filter(
                                        (v) => !responsibleFields.some((f) => f.value === v.id),
                                      )
                                      .map((v) => `objectID:${v.id}`)
                                      .join(' OR ')
                              }
                            />
                            <ResponsibleAutocomplete />
                          </InstantSearch>
                        )}
                        <div className={classes.inputContainer}>
                          {responsibleFields.map((item, index) => (
                            <div key={item.id}>
                              <ResponsibleListItem
                                userId={item.value}
                                remove={() => removeResponsible(index)}
                              />
                              <Controller
                                as={<input type='text' />}
                                name={`responsibleVolunteers[${index}]`}
                                defaultValue={item.value}
                                control={control}
                                style={{ display: 'none' }}
                              />
                            </div>
                          ))}
                        </div>
                      </Grid>
                    </Grid>

                    <Grid container item xs={12}>
                      <h4 className={classes.h4}>Collaboration</h4>
                      <Typography className={classes.p}>
                        Invite another student organisation to collaborate on content creation. A
                        co-creator will be listed as co-author of the content and the content will
                        show up on their page and feed, but they can’t edit the content.
                      </Typography>
                      <Grid item xs={6} className={classes.inputContainer}>
                        <InstantSearch searchClient={searchClient} indexName={contentIndexName}>
                          <Configure
                            filters={
                              `contentType:organization AND NOT objectID:${organizationId}` +
                              collaboratorFields.map((c) => ` AND NOT objectID:${c.id}`).join('')
                            }
                          />
                          <CollaboratorAutocomplete />
                        </InstantSearch>
                        <div className={classes.inputContainer}>
                          {collaboratorFields.map((item, index) => (
                            <div key={item.key}>
                              <CollaboratorListItem
                                organizationId={item.id}
                                status={item.status}
                                remove={() => removeCollaborator(index)}
                              />
                              <Controller
                                as={<input type='text' />}
                                name={`collaborators[${index}].id`}
                                defaultValue={item.id}
                                control={control}
                                style={{ display: 'none' }}
                              />
                              <Controller
                                as={<input type='text' />}
                                name={`collaborators[${index}].status`}
                                defaultValue={item.status}
                                control={control}
                                style={{ display: 'none' }}
                              />
                            </div>
                          ))}
                        </div>
                      </Grid>
                    </Grid>
                  </>
                )}
                <Grid container item xs={12}>
                  <Grid item xs={12}>
                    <h4 className={classes.h4}>Sign-up link</h4>
                    <Typography className={classes.p}>
                      Add a link to the event where students can sign up or read more.
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={6} className={classes.inputContainer}>
                  <Controller
                    rules={{
                      validate: (value) => {
                        if (!value) {
                          return true
                        }
                        return !!value.match(EMAIL_PATTERN)
                      },
                    }}
                    as={
                      <TextField
                        fullWidth
                        placeholder='https://signuplink.dk/'
                        label='Sign-up link'
                        variant='outlined'
                        error={errors.signupLink !== undefined}
                        helperText={
                          errors?.signupLink?.type === 'validate' ? 'Please input a valid url' : ''
                        }
                      />
                    }
                    name='signupLink'
                    control={control}
                  />
                </Grid>
              </Grid>
            </CardContent>

            <CardActions className={classes.cardActions}>
              <Button
                className={classes.secondaryButton}
                variant='outlined'
                disabled={loading}
                size='large'
                endIcon={<SaveIcon />}
                onClick={handleSubmit((data) =>
                  onOrganizationFormSubmit({
                    formData: data,
                    saveAsDraft: true,
                    currentEntityStatus: event?.status,
                    saveEntity: saveChanges,
                  }),
                )}
              >
                Save changes
              </Button>
              <div />
              <Button
                variant='contained'
                size='large'
                onClick={handleSubmit((data) =>
                  onOrganizationFormSubmit({
                    formData: data,
                    saveAsDraft: false,
                    currentEntityStatus: event?.status,
                    saveEntity: saveChanges,
                    isExpired: isEventExpired,
                    expirationDate: timeEnd,
                    showExpirationError: () =>
                      snackbar.showMessage('Please move the end of the event.'),
                  }),
                )}
                className={canBePublished ? classes.saveButton : classes.deleteButton}
                disabled={loading}
                endIcon={canBePublished ? <EventAvailableIcon /> : <Backspace />}
              >
                {canBePublished ? 'Publish' : 'Unpublish'}{' '}
              </Button>
            </CardActions>
          </form>
        </Card>
      </Container>
    </>
  )
}

export default compose(UserIsAuthenticated(EventPage))
