import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { AddCircle } from '@material-ui/icons'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { isEmpty, isLoaded, useFirestoreConnect } from 'react-redux-firebase'
import { useParams } from 'react-router-dom'
import { compose } from 'redux'
import LoadingSpinner from '../../../components/LoadingSpinner'
import { usePrivileged } from '../../../utils/profile'
import { UserIsAuthenticated } from '../../../utils/router'
import AddVolunteerDialog from './AddVolunteerDialog'
import Volunteer from './Volunteer'
import styles from './VolunteersPage.styles'

function VolunteersPage() {
  const { organizationId } = useParams()
  const [showAllVolunteers, setShowAllVolunteers] = useState(false)
  const [addVolunteerOpen, setAddVolunteerOpen] = useState(false)
  const [showVolunteerButton, setShowVolunteerButton] = useState(true)
  const storeAs = `organizationVolunteers${organizationId}`

  const privileged = usePrivileged(organizationId)

  const useStyles = makeStyles(styles)
  const classes = useStyles()

  // Attach volunteers listener
  useFirestoreConnect([
    {
      collection: 'organizations',
      doc: organizationId,
      subcollections: [
        {
          collection: 'volunteers',
          orderBy: [['createdAt', 'desc']],
        },
      ],
      storeAs: storeAs,
    },
  ])

  const volunteers = useSelector((state) => state.firestore.ordered[storeAs])
  const organization = useSelector((state) => state.firestore.data.organizations?.[organizationId])

  // Members are 'volunteers' and 'admins'
  const members = volunteers?.filter((volunteer) =>
    ['volunteer', 'admin'].includes(volunteer?.status),
  )

  useEffect(() => {
    if (isLoaded(volunteers)) {
      if (members.length < 9) {
        setShowAllVolunteers(true)
        setShowVolunteerButton(false)
      }
    }
  }, [members, volunteers])

  if (!isLoaded(volunteers)) {
    return <LoadingSpinner />
  }

  return (
    <>
      <AddVolunteerDialog
        open={addVolunteerOpen}
        handleClose={() => setAddVolunteerOpen(false)}
        handleAccept={() => setAddVolunteerOpen(false)}
        organizationId={organizationId}
        existingVolunteers={volunteers}
      />
      <Container className={classes.root} maxWidth='lg'>
        <Card className={classes.card}>
          <CardHeader
            className={classes.cardHeader}
            title={
              <>
                <h4 className={classes.heading}>Volunteers</h4>
                {!organization?.volunteers?.openForApplications && (
                  <div className={classes.greenDot} />
                )}
              </>
            }
            action={
              privileged && (
                <Button
                  color='primary'
                  className={classes.create}
                  variant='contained'
                  endIcon={<AddCircle />}
                  onClick={() => {
                    setAddVolunteerOpen(true)
                  }}
                >
                  Add volunteers
                </Button>
              )
            }
          />
          <CardContent>
            <TableContainer
              className={!showAllVolunteers ? classes.volunteerListContainer : classes.paddingRight}
            >
              <Table className={classes.table} aria-label='Planned events'>
                <TableHead>
                  <TableRow>
                    <TableCell className={`${classes.headTableCell} ${classes.firstCell}`} />
                    <TableCell className={classes.headTableCell}>Name</TableCell>
                    <TableCell className={classes.headTableCell}>User Type</TableCell>
                    <TableCell className={classes.headTableCell} align='right'>
                      Volunteer since
                    </TableCell>
                    <TableCell
                      className={`${classes.headTableCell} ${classes.lastCell}`}
                      align='right'
                    />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!isEmpty(volunteers) &&
                    members.map((volunteer) => (
                      <Volunteer
                        key={volunteer.id}
                        userId={volunteer.id}
                        volunteerStatus={volunteer.status}
                        acceptedAt={volunteer?.createdAt?.seconds}
                      />
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            {showVolunteerButton && (
              <div className={classes.showMoreContainer}>
                <Button
                  onClick={() => setShowAllVolunteers(!showAllVolunteers)}
                  className={classes.showMore}
                  classes={{ label: classes.showMoreLabel }}
                  variant='outlined'
                  color='primary'
                >
                  {showAllVolunteers ? 'Show less' : 'Show more'}
                </Button>
              </div>
            )}
          </CardContent>
        </Card>
      </Container>
    </>
  )
}

export default compose(UserIsAuthenticated(VolunteersPage))
