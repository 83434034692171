import React, { useState, useEffect } from 'react'
import { Button, Dialog, Grid, TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import VideoPreview from './VideoPreview'

const useStyle = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(6),
    borderRadius: '10px',
    width: '600px',
  },
  text: {
    marginBottom: theme.spacing(2.5),
  },
  button: {
    height: '36px',
    width: '100%',
  },
  ok: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
  },
  cancel: {
    backgroundColor: 'white',
    border: '1px solid #000000',
  },
  mtReset: {
    marginTop: 0,
  },
}))

const regExp =
  /(?:https?:\/\/)?(?:youtu\.be\/|(?:www\.|m\.)?youtube\.com\/(?:watch|v|embed)(?:\.php)?(?:\?.*v=|\/))([a-zA-Z0-9\-_]+)/

function AddVideoDialog({ open, handleCancel, handleAccept }) {
  const classes = useStyle()

  const [url, setUrl] = useState('')
  const youtubeId = url.match(regExp)?.[1]

  // Reset state on open and close
  useEffect(() => {
    setUrl('')
  }, [open])

  return (
    <Dialog open={open} onClose={handleCancel} PaperProps={{ className: classes.root }}>
      <h2 className={classes.text} variant='h4'>
        Add video
      </h2>
      <h4>YouTube link</h4>
      <TextField
        onChange={(e) => setUrl(e.target.value)}
        fullWidth
        placeholder='Insert YouTube link'
        type='text'
        variant='outlined'
      />
      <h4>Preview</h4>

      <VideoPreview videoId={youtubeId} />

      <Grid className={classes.mtReset} container spacing={6}>
        <Grid item xs={6}>
          <Button
            onClick={handleCancel}
            className={`${classes.button} ${classes.cancel}`}
            variant='outlined'
          >
            Cancel
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            onClick={() => handleAccept(youtubeId)}
            className={`${classes.button} ${classes.ok}`}
            disabled={!youtubeId}
            color='primary'
            variant='contained'
          >
            Add video
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  )
}

export default AddVideoDialog
