export default (theme) => ({
  root: {
    height: 'calc( 100vh - 80px )', // full page height (the navbar is 64px)
    display: 'flex',
    // marginBottom: theme.spacing(),
  },
  card: {
    padding: theme.spacing(2),
    height: '100%',
    marginLeft: 260,
    width: '100%',
    '& .MuiCardContent-root:last-child': {
      paddingBottom: 0,
    },
  },
  innerCard: {
    padding: 0,
    paddingTop: 0,
    backgroundColor: 'white',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardHeaderContainer: {
    borderBottom: '1px solid #EEEEEE',
    padding: theme.spacing(3),
  },
  cardHeader: {
    color: '#32305D',
    fontWeight: 'bold',
    margin: 0,
  },
  cardContent: {
    padding: 0,
    flexDirection: 'row',
    display: 'flex',
    flex: 1,
    overflow: 'auto',
  },
  leftMenu: {
    padding: theme.spacing(3),
    width: 430,
    minWidth: 430,
    borderRight: '1px solid #EEEEEE',
    overflowY: 'auto',
    height: '100%',
  },
  chatContainer: {
    width: '100%',
  },
})
