import React, { useState } from 'react'
import { useFirebase } from 'react-redux-firebase'
import { Menu, MenuItem, Button } from '@material-ui/core'
import { useSelector } from 'react-redux'
import AccountCircle from '@material-ui/icons/AccountCircle'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  buttonRoot: {
    color: '#32305D',
    '& .MuiButton-label': {
      textTransform: 'initial',
    },
  },
}))

function AccountMenu() {
  const classes = useStyles()
  const [anchorEl, setMenu] = useState(null)
  const firebase = useFirebase()
  const profile = useSelector((state) => state.firebase.profile)

  function closeAccountMenu() {
    setMenu(null)
  }
  function handleMenu(e) {
    setMenu(e.target)
  }
  function handleLogout() {
    return firebase.logout()
  }

  return (
    <>
      <Button
        aria-owns={anchorEl ? 'menu-appbar' : null}
        aria-haspopup='true'
        onClick={handleMenu}
        endIcon={<AccountCircle />}
        classes={{ root: classes.buttonRoot }}
      >
        {profile?.name || profile?.email || ''}
      </Button>
      <Menu
        id='menu-appbar'
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={Boolean(anchorEl)}
        onClose={closeAccountMenu}
      >
        <MenuItem onClick={handleLogout}>Sign Out</MenuItem>
      </Menu>
    </>
  )
}

export default AccountMenu
