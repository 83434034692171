import { makeStyles, TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import React from 'react'

export const getResponsibleAutocompleteComponent = ({
  id,
  noOptionsText,
  textFieldPlaceholder,
  onChange,
  classes,
  optionsFilter = (v) => v.name,
  getOptionLabel = (option) => (typeof option === 'string' ? option : option.name),
  setValueToCurrentRefinement,
  value,
}) =>
  function CustomAutocomplete({ hits, currentRefinement, refine }) {
    const defaultClasses = useStyle()
    if (!hits) {
      return null
    }
    return (
      <Autocomplete
        id={id}
        options={hits.filter(optionsFilter)}
        noOptionsText={noOptionsText}
        getOptionLabel={getOptionLabel}
        renderInput={(params) => (
          <TextField
            {...params}
            classes={{ root: defaultClasses.noBorder }}
            placeholder={textFieldPlaceholder}
            variant='outlined'
          />
        )}
        value={value ?? (setValueToCurrentRefinement ? currentRefinement : undefined)}
        onChange={onChange}
        onInputChange={(event, value) => refine(value)}
        classes={{ paper: defaultClasses.selectMenu, focused: defaultClasses.focused, ...classes }}
      />
    )
  }

const useStyle = makeStyles((theme) => ({
  root: {
    marginTop: 30,
    marginLeft: 260,
  },
  cardContent: {
    padding: theme.spacing(5),
    paddingBottom: 0,
  },
  heading: {
    marginTop: 0,
    marginBottom: 20,
    fontWeight: 'bold',
    color: '#32305D',
  },
  inputContainer: {
    marginTop: 15,
  },
  container: {
    margin: theme.spacing(4, 0),
  },
  addContentButton: {
    ...theme.elevation,
    width: '210px',
    height: '70px',
    marginRight: theme.spacing(4),
    '& .MuiButton-label': {
      justifyContent: 'center',
    },
    '&:hover': {
      ...theme.elevation,
    },
  },
  selectMenu: {
    boxShadow: 0,
    border: `2px solid ${theme.palette.primary.main}`,
  },
  saveButton: {
    '& .MuiButton-label': {
      display: 'flex',
      justifyContent: 'space-between',
    },
    minWidth: '50%',
    backgroundColor: '#07936B',
    color: '#FFFFFF',
    '&:hover': {
      color: '#FFFFFF',
      backgroundColor: '#07936B',
    },
  },
  divider: {
    marginTop: 40,
  },
  actions: {
    justifyContent: 'flex-end',
    padding: 18,
  },
}))
