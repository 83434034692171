import React from 'react'
import { Button, Dialog, Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyle = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(6),
    borderRadius: '10px',
    width: '600px',
  },
  text: {
    marginBottom: theme.spacing(2.5),
  },
  button: {
    height: '36px',
    width: '100%',
  },
  delete: {
    backgroundColor: theme.palette.red.main,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.red.hover,
    },
  },
  cancel: {
    backgroundColor: 'white',
    border: '1px solid #000000',
  },
  mtReset: {
    marginTop: 0,
  },
}))

function DeleteContentDialog({ open, handleAccept, handleCancel }) {
  const classes = useStyle()
  return (
    <Dialog open={open} onClose={handleCancel} PaperProps={{ className: classes.root }}>
      <Typography className={classes.text} variant='h5'>
        You have unsaved changes.
      </Typography>
      <Typography className={classes.text}>They will be lost if you close the dialog.</Typography>
      <Grid className={classes.mtReset} container spacing={6}>
        <Grid item xs={6}>
          <Button
            onClick={handleCancel}
            variant='outlined'
            className={`${classes.button} ${classes.cancel}`}
          >
            Cancel
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            onClick={handleAccept}
            variant='contained'
            className={`${classes.button} ${classes.delete}`}
          >
            Close
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  )
}

export default DeleteContentDialog
