import React, { useEffect, useState } from 'react'
import { isLoaded, useFirestoreConnect } from 'react-redux-firebase'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import LoadingSpinner from './LoadingSpinner'
import { Button, Card, CardContent, Container } from '@material-ui/core'
import CollaborationListItem from './CollaborationListItem'

const contentTypeToCollection = (contentType) => {
  switch (contentType) {
    case 'post':
      return 'posts'
    case 'event':
      return 'events'
    case 'collection':
      return 'collections'
    default:
      console.error('Did not recognize content type')
      return ''
  }
}
const useStyle = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    marginLeft: 260,
  },
  heading: {
    marginTop: 0,
    fontWeight: 'bold',
    color: '#32305D',
  },
  listContainer: {
    overflowX: 'hidden',
    maxHeight: 320,
    borderBottom: '2px solid #EEEEEE',
    paddingRight: 16,
  },
  paddingRight: {
    paddingRight: 32,
  },
  showMore: {
    margin: 24,
    fontWeight: 'bold',
  },
  showMoreContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  showMoreLabel: {
    justifyContent: 'center',
  },
}))
const DashboardCollaborations = ({ organizationId }) => {
  const classes = useStyle()
  const storeAs = `organizations${organizationId}Collaborations`

  const [showAllPending, setShowAllPending] = useState(false)
  const [showPendingButton, setShowPendingButton] = useState(true)
  const [showAllAccepted, setShowAllAccepted] = useState(false)
  const [showAcceptedButton, setShowAcceptedButton] = useState(true)

  useFirestoreConnect([
    {
      collection: 'organizations',
      doc: organizationId,
      subcollections: [
        {
          collection: 'collaborations',
        },
      ],
      storeAs: storeAs,
    },
  ])

  const organization = useSelector((state) => state.firestore.data.organizations?.[organizationId])
  const collaborations = useSelector((state) => state.firestore.ordered[storeAs])

  const pending = collaborations?.filter((c) => c.status === 'pending')
  const accepted = collaborations?.filter((c) => c.status === 'accepted')

  useEffect(() => {
    if (isLoaded(collaborations)) {
      if (pending.length < 3) {
        setShowAllPending(true)
        setShowPendingButton(false)
      }
      if (accepted.length < 3) {
        setShowAllAccepted(true)
        setShowAcceptedButton(false)
      }
    }
  }, [collaborations, pending, accepted])

  if (!isLoaded(organization, collaborations)) {
    return <LoadingSpinner />
  }

  return (
    <Container className={classes.root} maxWidth='md'>
      <Card className={classes.card}>
        <CardContent>
          <h2 className={classes.heading}>Collaboration</h2>
          <h4>Invitations ({pending && pending.length})</h4>
          <div className={!showAllPending ? classes.listContainer : classes.paddingRight}>
            {isLoaded(collaborations) &&
              pending.map((collaboration) => (
                <CollaborationListItem
                  key={collaboration.id}
                  organizationId={organizationId}
                  collaboratorId={collaboration.organizationId}
                  contentId={collaboration.id}
                  contentCollectionTitle={contentTypeToCollection(collaboration.contentType)}
                  status='pending'
                />
              ))}
          </div>
          {showPendingButton && (
            <div className={classes.showMoreContainer}>
              <Button
                onClick={() => setShowAllPending(!showAllPending)}
                variant='outlined'
                color='primary'
                className={classes.showMore}
                classes={{ label: classes.showMoreLabel }}
              >
                {showAllPending ? 'Show less' : 'Show all'}
              </Button>
            </div>
          )}
          <h4>Accepted collaborations ({accepted && accepted.length})</h4>
          <div className={!showAllAccepted ? classes.listContainer : classes.paddingRight}>
            {isLoaded(collaborations) &&
              accepted.map((collaboration) => (
                <CollaborationListItem
                  key={collaboration.id}
                  organizationId={organizationId}
                  collaboratorId={collaboration.organizationId}
                  contentId={collaboration.id}
                  contentCollectionTitle={contentTypeToCollection(collaboration.contentType)}
                  status='accepted'
                />
              ))}
          </div>
          {showAcceptedButton && (
            <div className={classes.showMoreContainer}>
              <Button
                onClick={() => setShowAllAccepted(!showAllAccepted)}
                variant='outlined'
                color='primary'
                className={classes.showMore}
                classes={{ label: classes.showMoreLabel }}
              >
                {showAllAccepted ? 'Show less' : 'Show all'}
              </Button>
            </div>
          )}
        </CardContent>
      </Card>
    </Container>
  )
}
export default DashboardCollaborations
