import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import { Container } from '@material-ui/core'
import { ORGANIZATIONS_PATH } from '../../constants/paths'
import styles from './HomePage.styles'

const useStyles = makeStyles(styles)

function Home() {
  const classes = useStyles()
  const history = useHistory()

  useEffect(() => {
    history.push(ORGANIZATIONS_PATH)
  }, [history])

  return <Container className={classes.root}>&nbsp;</Container>
}

export default Home
