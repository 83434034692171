import React from 'react'
import { Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyle = makeStyles((theme) => ({
  root: {
    marginRight: theme.spacing(4),
    '& .MuiButton-label': {
      justifyContent: 'center',
    },
    ...theme.elevation,
    width: '210px',
    height: '70px',
    '&:hover': {
      ...theme.elevation,
    },
  },
  ml1: {
    marginLeft: theme.spacing(1),
  },
}))

function AddContentButton({ icon, label, onClick }) {
  const classes = useStyle()

  return (
    <Button className={classes.root} color='primary' component='span' onClick={onClick}>
      {icon} <div className={classes.ml1}>{label}</div>
    </Button>
  )
}

export default AddContentButton
