export default () => ({
  tile: {
    padding: '5px !important',
    '& .MuiGridListTile-imgFullWidth': {
      transition: 'transform .1s ease-in-out',
    },
    '&:hover': {
      cursor: 'pointer',
      '& .MuiGridListTile-imgFullWidth': {
        transform: 'translateY(-50%) scale(1.05)',
      },
    },
  },
  header: {
    paddingTop: 5,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
  },
})
