import React from 'react'
import Typography from '@material-ui/core/Typography'
import { Link } from 'react-router-dom'
import { COLLECTION_PATH, EVENT_PATH, POST_PATH, CHAT_PATH } from '../../constants/paths'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  root: {
    border: '2px solid #EEEEEE',
    background: 'white',
    borderRadius: '12px 12px 12px 4px',
    display: 'block',
    color: '#707070',
    padding: '8px 10px',
    margin: 2,
    marginTop: 10,
    marginLeft: 10,
  },
  subText: {
    fontSize: 12,
  },
  description: {
    fontSize: 12,
    fontWeight: 'bold',
  },
}))
const ChatContext = ({ context, grouped }) => {
  const classes = useStyles()

  if (context.type === '') return <></>
  let link
  switch (context.type) {
    case 'post':
      link = POST_PATH(context.organizationId, context.id)
      break
    case 'event':
      link = EVENT_PATH(context.organizationId, context.id)
      break
    case 'collection':
      link = COLLECTION_PATH(context.organizationId, context.id)
      break
    case 'chat':
      link = `${CHAT_PATH(context.organizationId)}?chatId=${context.id}`
      break
    default:
      console.error('Badly formatted chat context?')
      link = null
  }
  return (
    <Link to={link} className={classes.root} style={{ marginLeft: grouped ? 46 : undefined }}>
      <Typography className={classes.subText}>Sent from</Typography>
      <Typography className={classes.description}>{context.description}</Typography>
    </Link>
  )
}

export default ChatContext
