const drawerWidth = 240

export default () => ({
  root: {
    display: 'flex',
    marginBottom: '64px',
  },
  toolbar: {
    justifyContent: 'center',
  },
  appBar: {
    backgroundColor: '#FFFFFF',
    color: '#32305D',
    boxShadow: '0px 3px 6px #AAAAAA29',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  actions: {
    position: 'absolute',
    right: 8,
  },
  drawerPaper: {
    top: 65,
    width: drawerWidth,
  },
  signIn: {
    color: '#32305D',
    textDecoration: 'none',
    alignSelf: 'center',
  },
  content: {
    flexGrow: 1,
    backgroundColor: 'red',
  },
})
