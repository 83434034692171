const drawerWidth = 240

export default () => ({
  root: {
    display: 'flex',
    '& .MuiTouchRipple-child': {
      backgroundColor: '#32305D !important',
    },
  },
  toolbar: {
    justifyContent: 'space-between',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  active: {
    display: 'block',
    backgroundColor: '#BABADD20',
    borderRight: 'solid 5px #32305D',
  },
  listItemIcon: {
    color: '#32305D',
  },
  listItemText: {
    color: '#32305D',
    fontWeight: 'bold !important',
  },
  drawerPaper: {
    top: 65,
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
  },
  signIn: {
    color: 'white',
    textDecoration: 'none',
    alignSelf: 'center',
  },
})
