import { useFirebase, useFirestore } from 'react-redux-firebase'

export const useFirebaseServerTimestamp = () => {
  return useFirebase().firestore.FieldValue.serverTimestamp
}

const createOrUpdateSubcollectionDoc = async (
  firestore,
  collection,
  doc,
  subcollection,
  subdoc,
  data,
) => {
  return firestore
    .collection(collection)
    .doc(doc)
    .collection(subcollection)
    .doc(subdoc)
    .set(data, { merge: true })
}

export const useFirestoreApi = () => {
  const firestore = useFirestore()

  return {
    createOrUpdateSubcollectionDoc: (...args) => createOrUpdateSubcollectionDoc(firestore, ...args),
  }
}
