export default (theme) => ({
  root: {
    padding: theme.spacing(2),
    marginLeft: 260,
  },
  cardHeader: {
    '& .MuiCardHeader-action': {
      marginTop: 0,
      marginRight: 0,
    },
  },
  create: {
    minWidth: 200,
    '& .MuiButton-endIcon': {
      marginLeft: 50,
    },
  },
  headTableCell: {
    fontWeight: 'bold',
    border: 0,
  },
  tableCell: {
    width: 200,
    border: 0,
  },
  tableCellSmall: {
    width: 50,
    border: 0,
  },
  tableIcon: {
    paddingRight: 5,
  },
  heading: {
    marginTop: 0,
    marginBottom: 0,
    fontWeight: 'bold',
    color: '#32305D',
  },
  published: {
    backgroundColor: '#88E288',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    fontSize: 10,
  },
  past: {
    backgroundColor: '#AAAAAA',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    fontSize: 10,
    padding: '0 12px',
  },
  deleted: {
    backgroundColor: '#AAAAAA',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    fontSize: 10,
    padding: '0 2px',
  },
  draft: {
    backgroundColor: '#AAAAAA',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    fontSize: 10,
    padding: '0 2px',
  },
  card: {
    marginTop: theme.spacing(3),
  },
})
