import React from 'react'
import Moment from 'moment'
import { Checkbox, Grid } from '@material-ui/core'
import { WatchLater as TimeIcon, LocationOn as LocationIcon } from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'

const useStyle = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '62px',
  },
  checkbox: {
    '& > span > svg': {
      width: '32px',
      height: '32px',
    },
  },
  mr1: {
    marginRight: theme.spacing(1),
  },
}))

function EventListItem({ title, location, timeBegin, timeEnd, toggle, added }) {
  const classes = useStyle()

  const Date = () => {
    const start = Moment(timeBegin * 1000).format('HH:mm')
    const end = Moment(timeEnd * 1000).format('HH:mm')
    const date = Moment(timeBegin * 1000).format('DD MMMM YYYY')
    return `${start} - ${end} ${date}`
  }

  return (
    <Grid container className={classes.root} alignItems='center'>
      <Grid container item xs={3} alignItems='center'>
        {title}
      </Grid>
      <Grid container item xs={4} alignItems='center'>
        <TimeIcon className={classes.mr1} />
        <Date />
      </Grid>
      <Grid container item xs={3} alignItems='center'>
        <LocationIcon className={classes.mr1} />
        {location}
      </Grid>
      <Grid container item xs={2} alignItems='center' justifyContent='center'>
        <Checkbox onChange={toggle} checked={added} color='primary' className={classes.checkbox} />
      </Grid>
    </Grid>
  )
}

export default EventListItem
