export default {
  palette: {
    primary: {
      main: '#5050A5',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#333333',
    },
    green: {
      main: '#07936B',
      hover: '#028558',
    },
    red: {
      main: '#B10024',
      hover: '#990015',
    },
  },
  // Enable typography v2: https://material-ui.com/style/typography/#migration-to-typography-v2
  typography: {
    fontFamily: ['Open Sans', 'Roboto', 'Arial'].join(','),
    useNextVariants: true,
  },
  flexColumnCenter: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  flexRowCenter: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  elevation: {
    boxShadow: '0 3px 6px #00000029',
  },
  overrides: {
    // Removes hover colors from all buttons
    MuiButton: {
      root: {
        borderRadius: '5px',
        minWidth: '210px',
      },
      contained: {
        boxShadow: '0 3px 6px #00000029',
        '&:hover': {
          boxShadow: '0 3px 6px #00000029',
        },
      },
      sizeLarge: {
        height: '60px',
        fontSize: '20px',
      },
      textPrimary: {
        backgroundColor: '#5050A5',
        color: 'white',
        '&:hover': {
          backgroundColor: '#424295',
        },
      },
      label: {
        display: 'flex',
        justifyContent: 'space-between',
        fontWeight: 'bold',
        textTransform: 'capitalize',
      },
    },
    MuiIconButton: {
      root: {
        color: '#AAAAAA',
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: '5px',
      },
    },
    MuiCard: {
      root: {
        borderRadius: '10px',
        boxShadow: '0 3px 6px #00000029',
        overflowY: 'auto',
      },
    },
    MuiCardHeader: {
      root: {
        padding: '24px',
      },
    },
    MuiCardContent: {
      root: {
        padding: '24px',
      },
    },
    MuiCardActions: {
      root: {
        padding: '24px',
      },
    },
    MuiSelect: {
      outlined: {
        padding: '12px 14px',
      },
    },
  },
}
