import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { AppBar, Toolbar } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector } from 'react-redux'
import { isLoaded, isEmpty } from 'react-redux-firebase'
import { ORGANIZATIONS_PATH, ROOMBOOKING_PATH } from '../constants/paths'
import AccountMenu from './AccountMenu'
import { ReactComponent as Logo } from '../static/logo.svg'
import styles from './Navbar.styles'

const useStyles = makeStyles(styles)

function Navbar() {
  const classes = useStyles()
  const history = useHistory()
  const [ignoreRoute, setIgnoreRoute] = useState(false)

  // Usage: include the path to exclude the navbar from a given route
  const ignoredRoutes = [ROOMBOOKING_PATH()]

  useEffect(() => {
    if (
      ignoredRoutes
        .map((x) => '^\\' + x.replace(/\/undefined/g, '\\/[^\\/]*') + '$')
        .reduce((a, b) => a || RegExp(b).test(history.location.pathname), false)
    )
      setIgnoreRoute(true)
  }, [history])

  // Get auth from redux state
  const auth = useSelector((state) => state.firebase.auth)
  const authExists = isLoaded(auth) && !isEmpty(auth)

  if (ignoreRoute) return null

  return (
    <div className={classes.root}>
      <AppBar position='fixed' className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <Link to={authExists ? ORGANIZATIONS_PATH : '/'}>
            <Logo />
          </Link>
          <div className={classes.actions}>{authExists && <AccountMenu />}</div>
        </Toolbar>
      </AppBar>
    </div>
  )
}

export default Navbar
