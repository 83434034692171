import React from 'react'
import { useParams } from 'react-router-dom'
import { useFirestoreConnect } from 'react-redux-firebase'
import { useSelector } from 'react-redux'
import { UserIsAuthenticated } from '../../../utils/router'
import { compose } from 'redux'
import DashboardCollaborations from '../../../components/DashboardCollaborations'

function Dashboard() {
  // Relevant parameters
  const { organizationId } = useParams()
  const storeOrganizationsAs = 'organizations'

  useFirestoreConnect([
    {
      collection: 'organizations',
      storeAs: storeOrganizationsAs,
    },
  ])
  const profile = useSelector((state) => state.firebase.profile)

  const privileged =
    profile?.isSuperAdmin ||
    profile?.organizations?.[organizationId].volunteerStatus === 'admin' ||
    profile?.organizations?.[organizationId].owner

  if (privileged) {
    return <DashboardCollaborations organizationId={organizationId} />
  }
  return <></>
}

export default compose(UserIsAuthenticated(Dashboard))
