import React from 'react'
import { Button, Dialog, Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyle = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(6),
    borderRadius: '10px',
    width: '600px',
  },
  text: {
    marginBottom: theme.spacing(2.5),
  },
  title: {
    fontWeight: 'bold',
  },
  button: {
    height: '36px',
    width: '100%',
  },
  ok: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
  },
  cancel: {
    backgroundColor: 'white',
    border: '1px solid #000000',
  },
  mtReset: {
    marginTop: 0,
  },
}))

function PinnedDialog({ open, handleCancel, handleAccept, title }) {
  const classes = useStyle()
  return (
    <Dialog open={open} onClose={handleCancel} PaperProps={{ className: classes.root }}>
      <Typography className={classes.text} variant='h5'>
        You already have pinned content
      </Typography>
      <Typography className={classes.text}>
        By pinning this you will unpin the following content:
      </Typography>
      <Typography className={`${classes.text} ${classes.title}`}>{title}</Typography>
      <Grid className={classes.mtReset} container spacing={6}>
        <Grid item xs={6}>
          <Button
            onClick={handleCancel}
            className={`${classes.button} ${classes.cancel}`}
            variant='outlined'
          >
            Cancel
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            onClick={handleAccept}
            className={`${classes.button} ${classes.ok}`}
            variant='contained'
            color='primary'
          >
            Ok
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  )
}

export default PinnedDialog
