export default (theme) => ({
  root: {
    position: 'relative',
    marginLeft: 260,
    marginTop: 50,
    marginBottom: 150,
    paddingBottom: 50,
  },
  card: {
    ...theme.elevation,
    borderRadius: '10px',
  },
  cardContent: {
    padding: theme.spacing(5),
    paddingBottom: 0,
  },
  cardActions: {
    padding: theme.spacing(5),
    justifyContent: 'space-between',
  },
  headingRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  heading: {
    marginTop: 0,
    marginBottom: 0,
    fontWeight: 'bold',
    color: '#32305D',
    flex: 1,
  },
  h4: {
    color: '#32305D',
  },
  p: {
    color: '#707070',
    marginBottom: theme.spacing(1),
  },
  inputContainer: {
    marginTop: 5,
  },
  secondaryButton: {
    backgroundColor: 'white',
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
    '&:hover': {
      backgroundColor: 'white',
    },
  },
  deleteButton: {
    backgroundColor: 'white',
    color: theme.palette.red.main,
    borderColor: theme.palette.red.main,
    border: `1px solid ${theme.palette.red.main}`,
    '&:hover': {
      backgroundColor: 'white',
    },
  },
  saveButton: {
    backgroundColor: theme.palette.green.main,
    color: '#FFFFFF',
    ...theme.elevation,
    '&:hover': {
      color: '#FFFFFF',
      backgroundColor: theme.palette.green.hover,
      ...theme.elevation,
    },
  },
  divider: {
    marginTop: 40,
  },
  mr1: {
    marginRight: theme.spacing(1),
  },
  mb1: {
    marginBottom: theme.spacing(1),
  },
  status: {
    flex: 1,
    marginTop: 0,
    textAlign: 'right',
  },
})
