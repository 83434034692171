import React, { useEffect } from 'react'
import { useFirestoreConnect, isLoaded } from 'react-redux-firebase'
import { useSelector } from 'react-redux'
import * as Sentry from '@sentry/browser'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, IconButton } from '@material-ui/core'
import { Close as CloseIcon } from '@material-ui/icons'
import AvatarWithFallback from '../components/AvatarWithFallback'
import { useSnackbar } from 'material-ui-snackbar-provider'

const useStyle = makeStyles({
  root: {
    backgroundColor: '#F5F5F5',
    borderRadius: 5,
    marginBottom: 12,
    paddingLeft: 16,
    paddingRight: 12,
    height: 44,
    fontWeight: 'bold',
  },
  avatar: {
    width: 30,
    height: 30,
    borderRadius: 20,
    marginRight: 10,
  },
})

function VolunteerListItem({ userId, remove }) {
  const classes = useStyle()
  const snackbar = useSnackbar()

  useFirestoreConnect([
    {
      collection: 'publicUserProfiles',
      doc: userId,
    },
  ])

  const user = useSelector((state) => state.firestore.data.publicUserProfiles?.[userId])

  useEffect(() => {
    if (isLoaded(user) && !user) {
      console.error('User exists in Algolia but not in Firestore.')
      snackbar.showMessage(
        'Failed to select the user. Please contact the administrator if the issue persists.',
      )
      Sentry.captureException(
        new Error(`User (userId: ${userId}) exists in Algolia but not in Firestore.`),
      )
      remove()
    }
  }, [user])

  return (
    isLoaded(user) &&
    user && (
      <Grid container alignItems='center' justifyContent='space-between' className={classes.root}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <AvatarWithFallback classes={classes} avatarUrl={user?.avatarUrl} />
          {user.name}
        </div>
        <Grid item>
          <IconButton size='small' onClick={remove}>
            <CloseIcon fontSize='small' />
          </IconButton>
        </Grid>
      </Grid>
    )
  )
}

export default VolunteerListItem
