import React, { useEffect, useState } from 'react'
import { ListItem } from '@material-ui/core'
import { isLoaded, useFirestoreConnect } from 'react-redux-firebase'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import moment from 'moment'
import Typography from '@material-ui/core/Typography'
import AvatarWithFallback from 'components/AvatarWithFallback'

const useStyles = makeStyles(() => ({
  root: {
    '.MuiTouchRipple-child': {
      backgroundColor: '#BABADD',
    },
    borderRadius: 10,
    marginBottom: 5,
  },
  infoContainer: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 15,
    width: '100%',
    maxWidth: 290,
  },
  nameUnreadContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 7,
    justifyContent: 'space-between',
  },
  username: {
    display: 'block',
    margin: 0,
    fontSize: 16,
    fontWeight: 'bold',
  },
  latestMessage: {
    display: 'block',
    margin: 0,
    fontSize: 16,
  },
  textActive: {
    color: '#32305D',
  },
  textBold: {
    fontWeight: 'bold',
  },
  blocked: {
    color: '#AAAAAA',
  },
  messageTimeContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  unreadDot: {
    backgroundColor: '#5050A5',
    width: 12,
    height: 12,
    minWidth: 12,
    minHeight: 12,
    borderRadius: 12,
    marginLeft: 'auto',
  },
  time: {
    display: 'block',
    margin: 0,
    whiteSpace: 'nowrap',
  },
  avatar: {
    width: 60,
    height: 60,
    borderRadius: 30,
  },
}))

const formatDate = (date) => {
  const momentDate = moment(date)
  const format = momentDate.isSame(moment(), 'day') ? 'HH:mm' : 'D MMM'
  return momentDate.format(format)
}
const ChatListItem = ({ chat, onClick, active }) => {
  const classes = useStyles()
  const [unread, setUnread] = useState(false)

  const userId = chat.userIds[0]
  const isChatCreated = Boolean(chat.latestMessage.createdAt)

  useFirestoreConnect({
    collection: 'publicUserProfiles',
    doc: userId,
  })

  const loggedInUserId = useSelector((state) => state.firebase.auth.uid)
  const chatsReadsStoreAs = `chatReads${chat.id}`
  useFirestoreConnect({
    collection: 'chats',
    doc: chat.id,
    subcollections: [
      {
        collection: 'reads',
        doc: loggedInUserId,
      },
    ],
    storeAs: chatsReadsStoreAs,
  })

  const user = useSelector((state) => state.firestore.data.publicUserProfiles?.[userId]) // TODO: Support group chats
  const read = useSelector((state) => state.firestore.data[chatsReadsStoreAs])

  useEffect(() => {
    setUnread(
      read == null ||
        (read.updatedAt && read.updatedAt.seconds < chat.latestMessage.createdAt.seconds),
    )
  }, [chat, read])

  if (!isLoaded(user)) return <></>

  return (
    <ListItem
      button
      onClick={onClick}
      className={classes.root}
      style={{
        backgroundColor: active ? 'rgba(186, 186, 221, 0.2)' : undefined,
      }}
    >
      <AvatarWithFallback classes={classes} avatarUrl={user?.avatarUrl} />
      <div className={classes.infoContainer}>
        <div className={classes.nameUnreadContainer}>
          <Typography
            variant='h5'
            className={`${classes.username} ${chat.blockedBy && classes.blocked} ${
              active && classes.textActive
            }`}
            noWrap
          >
            {user?.name}
          </Typography>
          <div className={classes.unreadDot} style={{ opacity: unread ? 1 : 0 }} />
        </div>
        <div className={classes.messageTimeContainer}>
          <Typography
            className={`${classes.latestMessage} ${chat.blockedBy && classes.blocked} ${
              active && classes.textActive
            } ${unread && classes.textBold}`}
            noWrap
          >
            {!chat.blockedBy ? chat.latestMessage.body : 'Chat blocked.'}
          </Typography>
          <p
            className={`${classes.time} ${chat.blockedBy && classes.blocked} ${
              active && classes.textActive
            } ${unread && classes.textBold}`}
          >
            · {isChatCreated ? formatDate(chat.latestMessage.createdAt.seconds * 1000) : ''}
          </p>
        </div>
      </div>
    </ListItem>
  )
}
export default ChatListItem
