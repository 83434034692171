import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { reduxForm, Field } from 'redux-form'
import TextField from './FormTextField'
import Button from '@material-ui/core/Button'
import { required, validateEmail } from '../utils/form'

const useStyles = makeStyles((theme) => ({
  root: {
    ...theme.flexColumnCenter,
    justifyContent: 'flex-start',
    flexGrow: 1,
    height: '100%',
    width: '100%',
    margin: '.2rem',
  },
  submit: {
    ...theme.flexColumnCenter,
    justifyContent: 'center',
    flexGrow: 1,
    textAlign: 'center',
    padding: '1.25rem',
    minWidth: '192px',
    marginTop: '1.5rem',
  },
}))

function LoginForm({ pristine, submitting, handleSubmit }) {
  const classes = useStyles()

  return (
    <form className={classes.root} onSubmit={handleSubmit}>
      <Field
        name='email'
        component={TextField}
        autoComplete='email'
        label='Email'
        validate={[required, validateEmail]}
      />
      <Field
        name='password'
        component={TextField}
        autoComplete='current-password'
        label='Password'
        type='password'
        validate={required}
      />
      <div className={classes.submit}>
        <Button color='primary' type='submit' variant='contained' disabled={pristine || submitting}>
          {submitting ? 'Loading' : 'Login'}
        </Button>
      </div>
    </form>
  )
}

export default reduxForm({ form: 'login' })(LoginForm)
