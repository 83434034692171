import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore' // make sure you add this for firestore
import 'firebase/storage'
import 'firebase/functions'
import { ReactReduxFirebaseProvider } from 'react-redux-firebase'
import { SnackbarProvider } from 'material-ui-snackbar-provider'
import { createFirestoreInstance } from 'redux-firestore'
import { Provider } from 'react-redux'
import ThemeSettings from '../theme'
import DateFnsUtils from '@date-io/date-fns'
import daLocale from 'date-fns/locale/da'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { Helmet } from 'react-helmet'
import {
  prodFirebaseConfig as fbProdConfig,
  stagingFirebaseConfig as fbStagingConfig,
  reduxFirebase as rfConfig,
} from '../config'
import getEnv from '../utils/getEnv'
const theme = createTheme(ThemeSettings)

let app
// Initialize Firebase instance
if (getEnv() === 'production') {
  app = firebase.initializeApp(fbProdConfig)
} else {
  console.warn(
    'App.js: Loaded Staging Firebase Config. If this is seen on production, please contact a developer immediately!',
  )
  app = firebase.initializeApp(fbStagingConfig)
}

if (location.hostname === 'localhost' && process.env.REACT_APP_ENVIRONMENT !== 'staging') {
  app.functions().useEmulator('http://localhost:5001')
  app.firestore().useEmulator('localhost', 8080)
  app.auth().useEmulator('http://localhost:9099/')
  app.storage().useEmulator('localhost', 9199)
}

function App({ routes, store }) {
  return (
    <div>
      <Helmet>
        <script
          type='text/javascript'
          src='https://maps.googleapis.com/maps/api/js?key=AIzaSyB1MloPHeTtY1HtpQNRugROEbShi69w43g&libraries=places'
        />
      </Helmet>
      <MuiThemeProvider theme={theme}>
        <Provider store={store}>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={daLocale}>
            <SnackbarProvider SnackbarProps={{ autoHideDuration: 4000 }}>
              <ReactReduxFirebaseProvider
                firebase={firebase}
                config={rfConfig}
                dispatch={store.dispatch}
                createFirestoreInstance={createFirestoreInstance}
              >
                <Router>{routes}</Router>
              </ReactReduxFirebaseProvider>
            </SnackbarProvider>
          </MuiPickersUtilsProvider>
        </Provider>
      </MuiThemeProvider>
    </div>
  )
}

export default App
