import React from 'react'
import { useFirestore, useFirestoreConnect, isLoaded } from 'react-redux-firebase'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Button } from '@material-ui/core'

const useStyle = makeStyles({
  root: {
    height: 142,
    backgroundColor: '#F5F5F5',
    borderRadius: 10,
    marginBottom: 16,
    paddingLeft: 24,
    paddingRight: 24,
  },
  title: {
    textTransform: 'capitalize',
    marginTop: 8,
    marginBottom: 8,
  },
  subtitle: {
    textTransform: 'uppercase',
    color: '#707070',
    fontSize: 12,
    marginTop: 8,
    marginBottom: 8,
  },
  description: {
    fontSize: 14,
    marginTop: 8,
    marginBottom: 8,
    maxHeight: 40,
    overflow: 'hidden',
    color: '#707070',
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
  },
  textContainer: {
    marginRight: 24,
  },
})

function CollaborationListItem({
  organizationId,
  collaboratorId,
  contentId,
  contentCollectionTitle,
}) {
  const classes = useStyle()
  const collaborationContentStoreAs = `organizations${collaboratorId}${contentCollectionTitle}${contentId}`
  const collaborationStoreAs = `organizations${collaboratorId}${contentCollectionTitle}${contentId}Collaboration`

  const firestore = useFirestore()

  useFirestoreConnect([
    {
      collection: 'organizations',
      doc: collaboratorId,
      subcollections: [
        {
          collection: contentCollectionTitle,
          doc: contentId,
        },
      ],
      storeAs: collaborationContentStoreAs,
    },
    {
      collection: 'organizations',
      doc: collaboratorId,
      subcollections: [
        {
          collection: contentCollectionTitle,
          doc: contentId,
          subcollections: [
            {
              collection: 'collaborators',
              doc: organizationId,
            },
          ],
        },
      ],
      storeAs: collaborationStoreAs,
    },
  ])

  const organization = useSelector((state) => state.firestore.data.organizations?.[collaboratorId])
  const content = useSelector((state) => state.firestore.data[collaborationContentStoreAs])
  const collaboration = useSelector((state) => state.firestore.data[collaborationStoreAs])

  const handleAccept = () => {
    firestore
      .collection('organizations')
      .doc(collaboratorId)
      .collection(contentCollectionTitle)
      .doc(contentId)
      .collection('collaborators')
      .doc(organizationId)
      .update({
        status: 'accepted',
      })
  }

  const handleDecline = () => {
    firestore
      .collection('organizations')
      .doc(collaboratorId)
      .collection(contentCollectionTitle)
      .doc(contentId)
      .collection('collaborators')
      .doc(organizationId)
      .delete()
  }

  return (
    isLoaded(content, collaboration) &&
    collaboration && (
      <Grid container alignItems='center' justifyContent='space-between' className={classes.root}>
        <Grid item xs={8} className={classes.textContainer}>
          <h4 className={classes.title}>
            {contentCollectionTitle} • 
            {contentCollectionTitle === 'events' ? content?.name : content?.title}
          </h4>
          <h5 className={classes.subtitle}>{organization?.name}</h5>
          <p className={classes.description}>{content?.description || content?.body}</p>
        </Grid>
        <Grid container xs={3} item direction='column' className={classes.buttonContainer}>
          {collaboration.status === 'pending' ? (
            <>
              <Button onClick={() => handleAccept()} variant='contained' color='primary'>
                Accept
              </Button>
              <Button
                onClick={() => handleDecline()}
                variant='outlined'
                style={{ backgroundColor: 'white', marginTop: 16 }}
              >
                Decline
              </Button>
            </>
          ) : (
            <Button
              onClick={() => handleDecline()}
              variant='outlined'
              style={{ backgroundColor: 'white' }}
            >
              Remove
            </Button>
          )}
        </Grid>
      </Grid>
    )
  )
}

export default CollaborationListItem
