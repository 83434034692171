import React, { useState, useEffect } from 'react'
import { useFirestoreConnect, isLoaded } from 'react-redux-firebase'
import { useSelector } from 'react-redux'
import _ from 'lodash'
import { Button, Dialog, DialogContent, DialogActions, Grid } from '@material-ui/core'
import PostListItem from './PostListItem'
import EventListItem from './EventListItem'
import { makeStyles } from '@material-ui/core/styles'

const useStyle = makeStyles((theme) => ({
  root: {
    minWidth: '1000px',
    maxHeight: '600px',
    padding: theme.spacing(6),
    borderRadius: '10px',
  },
  dialogTitle: {
    margin: theme.spacing(0, 0, 2, 0),
  },
  dialogContent: {
    padding: theme.spacing(2, 0),
    borderTop: '2px solid black',
    borderBottom: '2px solid black',
    scrollbarColor: 'transparent',
    fontSize: '16px',
  },
  tableHeadings: {
    padding: theme.spacing(2, 0),
    fontWeight: 'bold',
    textTransform: 'capitalize',
  },
  text: {
    marginBottom: theme.spacing(2.5),
  },
  button: {
    height: '36px',
    width: '230px',
  },
  ok: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
  },
  cancel: {
    backgroundColor: 'white',
    border: '1px solid #000000',
  },
  buttonsContainer: {
    marginTop: 0,
  },
}))

function AddContentDialog({ type, open, currentContent, organizationId, handleAccept }) {
  const storeAs = `organizationCollections${organizationId}${type}s`
  const [updatedContent, setUpdatedContent] = useState(undefined)

  const classes = useStyle()

  // Attach firestore listener
  useFirestoreConnect([
    {
      collection: 'organizations',
      doc: organizationId,
      subcollections: [
        {
          collection: `${type}s`,
        },
      ],
      storeAs: storeAs,
    },
  ])

  let allContent = useSelector((state) => state.firestore.ordered[storeAs])

  // Filter away deleted content and add content id to each object
  if (allContent) allContent = allContent.filter((content) => content.status !== 'deleted')

  // 'Cancel' means calling handleAccept with the original list - 'currentContent' - rather then the updated one.
  useEffect(() => {
    setUpdatedContent(currentContent.map(({ fieldId, ...items }) => items))
  }, [open, currentContent])

  const toggleContent = (id) => {
    if (_.map(updatedContent, 'id').includes(id)) {
      setUpdatedContent(updatedContent.filter((o) => o.id !== id))
    } else {
      setUpdatedContent((u) => [...u, { type: type, id: id }])
    }
  }

  return (
    <Dialog
      open={open}
      onClose={() => handleAccept(currentContent)}
      PaperProps={{ className: classes.root }}
    >
      <h2 className={classes.dialogTitle}>Add {type}s</h2>
      <Grid container className={classes.tableHeadings}>
        {type === 'post' && (
          <>
            <Grid item xs={5}>
              {type}
            </Grid>
            <Grid item xs>
              Type
            </Grid>
          </>
        )}
        {type === 'event' && (
          <>
            <Grid item xs={3}>
              {type}
            </Grid>
            <Grid item xs={4}>
              Date
            </Grid>
            <Grid item xs={3}>
              Location
            </Grid>
          </>
        )}
        <Grid item xs={2} />
      </Grid>
      <DialogContent className={classes.dialogContent}>
        {allContent &&
          isLoaded(allContent) &&
          (type === 'post'
            ? allContent.map((item) => (
                <PostListItem
                  key={item.id}
                  title={item.title}
                  mediaType={item?.media?.type}
                  toggle={() => toggleContent(item.id)}
                  added={_.map(updatedContent, 'id').includes(item.id)}
                />
              ))
            : allContent.map((item) => (
                <EventListItem
                  key={item.id}
                  title={item.name}
                  timeBegin={item.timeBegin.seconds}
                  timeEnd={item.timeEnd.seconds}
                  location={item.location}
                  toggle={() => toggleContent(item.id)}
                  added={_.map(updatedContent, 'id').includes(item.id)}
                />
              )))}
      </DialogContent>
      <DialogActions>
        <Grid className={classes.buttonsContainer} container spacing={6} justifyContent='flex-end'>
          <Grid item>
            <Button
              onClick={() => handleAccept(currentContent)}
              className={`${classes.button} ${classes.cancel}`}
              variant='outlined'
            >
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button
              onClick={() => handleAccept(updatedContent)}
              className={`${classes.button} ${classes.ok}`}
              disabled={false}
              color='primary'
              variant='contained'
            >
              Add
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}

export default AddContentDialog
