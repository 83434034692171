import React from 'react'
import { useFirestoreConnect, isLoaded, isEmpty } from 'react-redux-firebase'
import { useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import {
  Box,
  Button,
  Card,
  CardHeader,
  CardContent,
  Chip,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core'
import {
  AddCircle as AddCircleIcon,
  ThumbUp as ThumbUpIcon,
  Chat as ChatIcon,
} from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'
import styles from './PostsPage.styles'
import LoadingSpinner from '../../../components/LoadingSpinner'
import ListItemButton from '../../../components/ListItemButton'
import { POST_PATH } from '../../../constants/paths'
import moment from 'moment'
import { UserIsAuthenticated } from '../../../utils/router'
import { compose } from 'redux'
import clsx from 'clsx'

function PostsPage() {
  const { organizationId } = useParams()
  const history = useHistory()
  const storeAs = `organizationPosts${organizationId}`

  const useStyles = makeStyles(styles)
  const classes = useStyles()

  // Attach posts listener
  useFirestoreConnect([
    {
      collection: 'organizations',
      doc: organizationId,
      subcollections: [
        {
          collection: 'posts',
        },
      ],
      storeAs: storeAs,
    },
  ])

  const posts = useSelector((state) => state.firestore.ordered[storeAs])
  const profile = useSelector((state) => state.firebase.profile)
  const auth = useSelector((state) => state.firebase.auth)

  if (isLoaded(profile)) {
    var privileged =
      profile?.isSuperAdmin ||
      profile?.organizations?.[organizationId]?.volunteerStatus === 'admin' ||
      profile?.organizations?.[organizationId].owner
  }

  if (!isLoaded(posts)) {
    return <LoadingSpinner />
  }

  const goToPost = (post) => {
    history.push(POST_PATH(organizationId, post))
  }

  return (
    <>
      <Container className={classes.root} maxWidth='md'>
        <Card>
          <CardHeader
            className={classes.cardHeader}
            title={<h4 className={classes.heading}>Posts</h4>}
            action={
              privileged && (
                <Button
                  color='primary'
                  className={classes.create}
                  variant='contained'
                  endIcon={<AddCircleIcon />}
                  onClick={() => {
                    goToPost('new')
                  }}
                >
                  Create Post
                </Button>
              )
            }
          />
          <CardContent>
            <TableContainer>
              <Table className={classes.table} aria-label='Planned events'>
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.headTableCell}>Title</TableCell>
                    <TableCell className={classes.headTableCell}>Created</TableCell>
                    <TableCell className={classes.headTableCell}>Activity</TableCell>
                    <TableCell className={classes.headTableCell}>Status</TableCell>
                    <TableCell className={classes.headTableCell} align='right' />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!isEmpty(posts) &&
                    posts.map((post) => (
                      <TableRow key={post.id}>
                        <TableCell className={classes.tableCell} component='th' scope='row'>
                          {post.title}
                        </TableCell>
                        <TableCell className={classes.tableCell} component='th' scope='row'>
                          {moment.unix(post.createdAt.seconds).format('D MMM, YYYY')}
                        </TableCell>
                        <TableCell className={classes.tableCell} component='th' scope='row'>
                          <Box
                            alignItems='center'
                            display='flex'
                            className={clsx({
                              [classes.activity]: post.numComments > 0 || post.numLikes > 0,
                              [classes.noActivity]: !post.numComments || !post.numLikes,
                            })}
                          >
                            <ThumbUpIcon className={classes.tableIcon} />
                            {post.numLikes}
                            <ChatIcon className={`${classes.tableIcon} ${classes.chatIcon}`} />
                            {post.numComments}
                          </Box>
                        </TableCell>
                        <TableCell className={classes.tableCell} component='th' scope='row'>
                          <Chip size='small' className={classes[post.status]} label={post.status} />
                        </TableCell>
                        <TableCell className={classes.tableCellSmall} align='right'>
                          {(privileged || post?.responsibleVolunteers?.includes(auth.uid)) && (
                            <ListItemButton
                              label='Edit'
                              onClick={() => {
                                goToPost(post.id)
                              }}
                            />
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
        </Card>
      </Container>
    </>
  )
}

export default compose(UserIsAuthenticated(PostsPage))
