import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    width: '100%',
  },
  progress: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '50%',
    width: '100%',
  },
}))

// Seems like this is only made for loading whole pages - this is not a generic component
function LoadingSpinner() {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div className={classes.progress}>
        <CircularProgress disableShrink mode='determinate' />
      </div>
    </div>
  )
}

export default LoadingSpinner
