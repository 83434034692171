import React, { useState } from 'react'
import { useFirestoreConnect, isLoaded } from 'react-redux-firebase'
import { useSelector } from 'react-redux'
import Moment from 'moment'
import { IconButton, Grid, Typography } from '@material-ui/core'
import {
  Delete as DeleteIcon,
  KeyboardArrowUp as UpIcon,
  KeyboardArrowDown as DownIcon,
  Event as EventIcon,
  Description as PostIcon,
  YouTube as VideoIcon,
  Image as ImageIcon,
  SettingsInputAntenna as PodcastIcon,
} from '@material-ui/icons'
import DeleteContentDialog from './DeleteContentDialog'
import { makeStyles } from '@material-ui/core/styles'

const useStyle = makeStyles((theme) => ({
  root: {
    minWidth: '100%',
    height: '64px',
    margin: theme.spacing(2, 0),
  },
  moveButtons: {
    width: '50px',
    height: '100%',
  },
  moveButton: {
    height: '32px',
    width: '32px',
    margin: '0 auto',
  },
  imgContainer: {
    position: 'relative',
    maxWidth: '114px',
    maxHeight: '63px',
    borderRadius: '5px',
    overflow: 'hidden',
    marginRight: theme.spacing(4),
  },
  img: {
    objectFit: 'cover',
    maxWidth: '200px',
  },
  placeholder: {
    width: '100%',
    height: '100%',
    backgroundColor: '#EEEEEE',
  },
  name: {
    fontWeight: 'bold',
  },
  deleted: {
    color: '#999999',
  },
  date: {
    color: 'gray',
  },
  deleteIcon: {
    height: '26px',
    width: '26px',
  },
}))

function ContentPreview({ type, id, move, deleteFunc, arrayLength, i, organizationId }) {
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const classes = useStyle()

  const storeAs = `organizationCollections${organizationId}${type}s${id}`

  // Attach collections listener
  useFirestoreConnect([
    {
      collection: 'organizations',
      doc: organizationId,
      subcollections: [
        {
          collection: `${type}s`,
          doc: id,
        },
      ],
      storeAs: storeAs,
    },
  ])

  const content = useSelector((state) => state.firestore.data[storeAs])

  const ContentIcon = () => {
    if (type === 'event') {
      return <EventIcon />
    } else {
      switch (content?.media?.type) {
        case 'photos':
          return <ImageIcon />
        case 'video':
          return <VideoIcon />
        case 'audio':
          return <PodcastIcon />
        default:
          return <PostIcon />
      }
    }
  }

  const Date = () => {
    if (type === 'event') return Moment(content.timeBegin.seconds * 1000).format('DD MMMM YYYY')
    return Moment(content.createdAt.seconds * 1000).format('DD MMMM YYYY')
  }

  return (
    <>
      <DeleteContentDialog
        open={deleteDialogOpen}
        type={type}
        handleAccept={deleteFunc}
        handleCancel={() => setDeleteDialogOpen(false)}
      />
      {content && isLoaded(content) && (
        <Grid container item className={classes.root}>
          <Grid
            container
            className={classes.moveButtons}
            direction='column'
            justifyContent='center'
          >
            <IconButton
              onClick={() => move(i, i - 1)}
              disabled={i === 0}
              className={classes.moveButton}
              disableRipple
            >
              <UpIcon />
            </IconButton>
            <IconButton
              onClick={() => move(i, i + 1)}
              disabled={i === arrayLength - 1}
              className={classes.moveButton}
              disableRipple
            >
              <DownIcon />
            </IconButton>
          </Grid>
          <Grid container item className={classes.imgContainer}>
            <Grid
              container
              justifyContent='center'
              alignItems='center'
              className={classes.placeholder}
            >
              <ContentIcon />
            </Grid>
          </Grid>
          <Grid container item xs justifyContent='space-between' alignItems='center'>
            <Grid item>
              <Typography
                className={`${classes.name} ${content.status === 'deleted' && classes.deleted}`}
              >
                {type === 'event' ? content.name : content.title}{' '}
                {content.status === 'deleted' && '(deleted)'}
              </Typography>
              <Typography className={classes.date}>
                <Date />
              </Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={() => setDeleteDialogOpen(true)}>
                <DeleteIcon className={classes.deleteIcon} />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  )
}

export default ContentPreview
