import { Button, CircularProgress, Dialog, Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Save } from '@material-ui/icons'
import VolunteerListItem from 'components/VolunteerListItem'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { Configure, connectAutoComplete, InstantSearch } from 'react-instantsearch-dom'
import { useFirebase } from 'react-redux-firebase'
import { useFirestoreApi } from 'utils/firebase'
import searchClient, { usersIndexName } from '../../../utils/algolia'
import React from 'react'
import { getResponsibleAutocompleteComponent } from 'components/CustomAutocomplete'

const AddVolunteerDialog = ({
  open,
  handleAccept,
  handleClose,
  organizationId,
  existingVolunteers,
}) => {
  const classes = useStyle()
  const firebase = useFirebase()
  const firestoreApi = useFirestoreApi()

  const AutocompleteComponent = connectAutoComplete(
    getResponsibleAutocompleteComponent({
      id: 'user-autocomplete',
      noOptionsText: 'No matching user found',
      optionsFilter: (option) => {
        return (
          !existingVolunteers.map((v) => v.id).includes(option.objectID) &&
          !volunteerFields.map((v) => v.value).includes(option.objectID) &&
          option.name
        )
      },
      textFieldPlaceholder: 'Select a volunteer',
      getOptionLabel: (option) => (typeof option === 'string' ? option : option.email),
      onChange: (event, value) => (value?.objectID ? appendVolunteer(value.objectID) : null),
      setValueToCurrentRefinement: false,
    }),
  )

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
    reset,
  } = useForm({
    defaultValues: {
      volunteers: [],
    },
  })
  const {
    fields: volunteerFields,
    append: appendVolunteer,
    remove: removeVolunteer,
  } = useFieldArray({
    control,
    name: 'volunteers',
  })

  const onSubmit = async () => {
    const $ = volunteerFields
      .map((v) => v.value)
      .map((volunteerId) => {
        return firestoreApi.createOrUpdateSubcollectionDoc(
          'organizations',
          organizationId,
          'volunteers',
          volunteerId,
          {
            status: 'volunteer',
            createdAt: firebase.firestore.FieldValue.serverTimestamp(),
          },
        )
      })
    await Promise.all($)
    reset()
    handleAccept()
  }

  // Used to bypass a react-hook-form issue when deleting from inside VolunteerListItem
  const handleRemove = (index) => {
    if (index === 0 && volunteerFields.length === 1) removeVolunteer()
    else removeVolunteer(index)
  }

  return (
    <Dialog open={open} onClose={handleClose} PaperProps={{ className: classes.root }}>
      <Typography className={classes.text} variant='h5'>
        Add volunteers
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container item xs={12}>
          <Grid item xs={12} className={classes.inputContainer}>
            <InstantSearch searchClient={searchClient} indexName={usersIndexName}>
              <Configure hitsPerPage={100} />
              <AutocompleteComponent />
            </InstantSearch>
            <div className={classes.inputContainer}>
              {volunteerFields.map((item, index) => (
                <div key={item.id}>
                  <VolunteerListItem userId={item.value} remove={() => handleRemove(index)} />
                  <Controller
                    as={<input type='text' />}
                    name={`volunteers[${index}]`}
                    defaultValue={item.value}
                    control={control}
                    style={{ display: 'none' }}
                  />
                </div>
              ))}
            </div>
          </Grid>
        </Grid>
        <Grid className={classes.mtReset} container spacing={6}>
          <Grid item xs={6}>
            <Button
              variant='outlined'
              className={`${classes.button} ${classes.cancel}`}
              onClick={handleClose}
            >
              Cancel
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              onClick={handleAccept}
              variant='contained'
              className={`${classes.button} ${classes.save}`}
              disabled={!volunteerFields.length}
              type='submit'
            >
              Add volunteers
              {!isSubmitting ? <Save /> : <CircularProgress size={'1.2em'} color={'secondary'} />}
            </Button>
          </Grid>
        </Grid>
      </form>
    </Dialog>
  )
}

const useStyle = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(6),
    borderRadius: '10px',
    width: '600px',
  },
  text: {
    marginBottom: theme.spacing(2.5),
  },
  button: {
    height: '36px',
    width: '100%',
  },
  delete: {
    backgroundColor: theme.palette.red.main,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.red.hover,
    },
  },
  save: {
    backgroundColor: theme.palette.green.main,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.green.hover,
    },
  },
  cancel: {
    backgroundColor: 'white',
    border: '1px solid #000000',
  },
  mtReset: {
    marginTop: 0,
  },
  inputContainer: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}))

export default AddVolunteerDialog
