import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'block',
    width: '100%',
    height: '100%',
  },
  frame: {
    marginBottom: theme.spacing(3),
  },
  placeholder: {
    height: '216px',
    width: '375px',
    border: '1px solid #333333',
    backgroundColor: 'lightgray',
    marginBottom: theme.spacing(3),
  },
}))

function VideoPreview({ videoId }) {
  const srcUrl = 'https://www.youtube.com/embed/' + videoId

  const classes = useStyles()

  return (
    <div className={classes.root}>
      {videoId ? (
        <iframe
          src={srcUrl}
          width='375'
          height='211'
          className={classes.frame}
          frameBorder='0'
          allow='autoplay; encrypted-media'
          allowFullScreen
          title='youtube-video'
        />
      ) : (
        <div className={classes.placeholder} />
      )}
    </div>
  )
}

export default VideoPreview
