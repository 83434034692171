/**
 * NOTE: This file is ignored from git tracking. In a CI environment it is
 * generated by firebase-ci based on config in .firebaserc (see .gitlab-ci.yaml).
 * This is done so that environment specific settings can be applied.
 */

export const env = 'local'

// Config for firebase
export const prodFirebaseConfig = {
  apiKey: 'AIzaSyDqdHynkhYRSPxvO-3UlMB_2hOWStGnMdg',
  authDomain: 'studentlife-8f814.firebaseapp.com',
  databaseURL: 'https://studentlife-8f814.firebaseio.com',
  projectId: 'studentlife-8f814',
  storageBucket: 'studentlife-8f814.appspot.com',
  messagingSenderId: '809136272543',
  appId: '1:809136272543:web:0ca4c80cb56780fe2b1eaa',
  measurementId: 'G-9BQZXXE2JN',
}

export const stagingFirebaseConfig = {
  apiKey: 'AIzaSyD3yPysOf1MwN0MRLbFVmIE5nDMk_JMdt0',
  authDomain: 'student-life-staging.firebaseapp.com',
  databaseURL: 'https://student-life-staging.firebaseio.com',
  projectId: 'student-life-staging',
  storageBucket: 'student-life-staging.appspot.com',
  messagingSenderId: '444968406998',
  appId: '1:444968406998:web:6eeb46f988390fa7d69e77',
  measurementId: 'G-9BQZXXE2JN',
}

// Config to override default reduxFirebase config in store/createStore
// which is not environment specific.
// For more details, visit http://react-redux-firebase.com/docs/api/enhancer.html
export const reduxFirebase = {
  userProfile: 'users', // root that user profiles are written to
  useFirestoreForProfile: true, // Save profile to Firestore instead of Real Time Database
  useFirestoreForStorageMeta: true, // Metadata associated with storage file uploads goes to Firestore
}

export default {
  env,
  prodFirebaseConfig,
  stagingFirebaseConfig,
  reduxFirebase,
}
