import React from 'react'
import { isEmpty, isLoaded, useFirestoreConnect } from 'react-redux-firebase'
import { useHistory, useParams } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import {
  Card,
  CardHeader,
  CardContent,
  Container,
  Chip,
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core/'
import {
  AddCircle as AddCircleIcon,
  Event as EventIcon,
  Description as PostIcon,
} from '@material-ui/icons'
import { useSelector } from 'react-redux'
import { COLLECTION_PATH } from '../../../constants/paths'
import LoadingSpinner from '../../../components/LoadingSpinner'
import ListItemButton from '../../../components/ListItemButton'
import styles from './CollectionsPage.styles'
import { UserIsAuthenticated } from '../../../utils/router'
import { compose } from 'redux'

const useStyles = makeStyles(styles)

function useCollections() {
  // Get auth from redux state
  const history = useHistory()
  const { organizationId } = useParams()
  const storeAs = `organizationCollections${organizationId}`

  // Attach collections listener
  useFirestoreConnect([
    {
      collection: 'organizations',
      doc: organizationId,
      subcollections: [
        {
          collection: 'collections',
        },
      ],
      storeAs: storeAs,
    },
  ])

  let collections = useSelector((state) => state.firestore.data[storeAs])
  if (collections) collections = Object.entries(collections).map((e) => ({ id: e[0], ...e[1] }))

  return { collections, history, organizationId }
}

function CollectionsPage() {
  const classes = useStyles()
  const { collections, history, organizationId } = useCollections()

  const profile = useSelector((state) => state.firebase.profile)
  const auth = useSelector((state) => state.firebase.auth)

  if (isLoaded(profile)) {
    var privileged =
      profile?.isSuperAdmin ||
      profile?.organizations?.[organizationId]?.volunteerStatus === 'admin' ||
      profile?.organizations?.[organizationId].owner
  }

  // Show spinner while projects are loading
  if (!isLoaded(collections)) {
    return <LoadingSpinner />
  }

  const goToCollection = (collectionId) => {
    history.push(COLLECTION_PATH(organizationId, collectionId))
  }

  const getCounts = (content) => {
    const contentTypes = ['event', 'post']
    const counts = contentTypes
      .map((type) => {
        const count = content?.filter((content) => content.type === type)?.length || 0
        return { type, count }
      })
      .filter((contentCount) => contentCount.count > 0)

    const contentIcon = (type) => {
      switch (type) {
        case 'event':
          return <EventIcon className={classes.tableIcon} />
        default:
          return <PostIcon className={classes.tableIcon} />
      }
    }

    const contentCountComponent = ({ type, count }, index) => (
      <React.Fragment key={index}>
        {contentIcon(type)}
        {count}
      </React.Fragment>
    )

    return counts.map(contentCountComponent)
  }

  return (
    <>
      <Container className={classes.root} maxWidth='md'>
        <Card>
          <CardHeader
            className={classes.cardHeader}
            title={<h4 className={classes.heading}>Collections</h4>}
            action={
              privileged && (
                <Button
                  color='primary'
                  className={classes.create}
                  variant='contained'
                  endIcon={<AddCircleIcon />}
                  onClick={() => {
                    goToCollection('new')
                  }}
                >
                  Create Collection
                </Button>
              )
            }
          />
          <CardContent>
            <TableContainer>
              <Table className={classes.table} aria-label='Collections'>
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.headTableCell}>Title</TableCell>
                    <TableCell className={classes.headTableCell}>Content</TableCell>
                    <TableCell className={classes.headTableCell}>Status</TableCell>
                    <TableCell className={classes.headTableCell} align='right' />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!isEmpty(collections) &&
                    collections.map((collection) => {
                      return (
                        <TableRow key={collection.id}>
                          <TableCell className={classes.tableCell} component='th' scope='row'>
                            {collection.title}
                          </TableCell>
                          <TableCell className={classes.tableCell} component='th' scope='row'>
                            <Box alignItems='center' display='flex' className={classes.activity}>
                              {getCounts(collection.content)}
                            </Box>
                          </TableCell>
                          <TableCell className={classes.tableCellSmall}>
                            <Chip
                              size='small'
                              className={classes[collection.status]}
                              label={collection.status}
                            />
                          </TableCell>
                          <TableCell className={classes.tableCellSmall} align='right'>
                            {(privileged ||
                              collection?.responsibleVolunteers?.includes(auth.uid)) && (
                              <ListItemButton
                                label='Edit'
                                onClick={() => {
                                  goToCollection(collection.id)
                                }}
                              />
                            )}
                          </TableCell>
                        </TableRow>
                      )
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
        </Card>
      </Container>
    </>
  )
}
export default compose(UserIsAuthenticated(CollectionsPage))
