import React from 'react'
import moment from 'moment'
import { TextField } from '@material-ui/core'

const DateTimePicker = ({ timeValue, handleTimeChange, label, variant, style, minDate }) => {
  return (
    <TextField
      label={label}
      type='datetime-local'
      value={moment(timeValue).format('yyyy-MM-DDTHH:mm')}
      onChange={(event) =>
        handleTimeChange(event.target.value ? new Date(event.target.value) : null)
      }
      inputProps={{
        min: minDate ? moment(minDate).format().slice(0, 16) : moment().format().slice(0, 16),
      }}
      InputLabelProps={{
        shrink: true,
      }}
      style={style}
      variant={variant || 'standard'}
    />
  )
}
export default DateTimePicker
