import React, { useState, useEffect } from 'react'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import styles from './ChatPage.styles'
import { useFirestoreConnect, isLoaded, useFirestore } from 'react-redux-firebase'
import LoadingSpinner from '../../../components/LoadingSpinner'
import { useSelector } from 'react-redux'
import { UserIsAuthenticated } from '../../../utils/router'
import { compose } from 'redux'
import { Card, CardContent, Container, List } from '@material-ui/core'
import ChatListItem from '../../../components/chat/ChatListItem'
import ChatContainer from '../../../components/chat/ChatContainer'
import Typography from '@material-ui/core/Typography'
import { CHAT_PATH } from '../../../constants/paths'

const useStyles = makeStyles(styles)

const getChatFromUserId = (userId, chats) => {
  const chat = chats.filter((chat) => chat.userIds.length === 1 && chat.userIds.includes(userId))
  return chat[0]
}

const useQuery = () => {
  return new URLSearchParams(useLocation().search)
}

function ChatPage() {
  const [currentChat, setCurrentChat] = useState(null)

  const { organizationId } = useParams()
  const history = useHistory()

  const chatIdQuery = useQuery()?.get('chatId')
  const [hasRedirectedToChat, setHasRedirectedToChat] = useState(false)

  const classes = useStyles()

  const firestore = useFirestore()
  const chatsStoreAs = `Organization${organizationId}Chats`

  useFirestoreConnect([
    {
      collection: 'chats',
      where: ['organizationId', '==', organizationId],
      storeAs: chatsStoreAs,
      orderBy: ['latestMessage.createdAt', 'desc'],
    },
  ])
  const chats = useSelector((state) => state.firestore.ordered[chatsStoreAs])
  const userId = useSelector((state) => state.firebase.auth.uid)

  // Try to find a matching chat -> If found, setCurrentChat(chatId)
  useEffect(() => {
    const chatUserIdFromPath = history.location?.chatUserId
    if (isLoaded(chats) && chatUserIdFromPath) {
      const chat = getChatFromUserId(chatUserIdFromPath, chats)
      if (chat) {
        setCurrentChat(chat)
        onRead(chat)
        history.location.chatUserId = undefined
      }
    }
  }, [chats, history.location, setCurrentChat])

  useEffect(() => {
    if (chatIdQuery && !hasRedirectedToChat && isLoaded(chats)) {
      chats.forEach((chat) => {
        if (chat.id === chatIdQuery) {
          setCurrentChat(chat)
          setHasRedirectedToChat(true)
          window.history.replaceState({}, document.title, CHAT_PATH(organizationId))
        }
      })
    }
  }, [chatIdQuery, chats, organizationId, currentChat, hasRedirectedToChat])

  const onRead = (chat) => {
    firestore.collection('chats').doc(chat.id).collection('reads').doc(userId).set({
      updatedAt: firestore.FieldValue.serverTimestamp(),
    })
  }

  if (!isLoaded(chats)) {
    return (
      <div className={classes.root}>
        <LoadingSpinner />
      </div>
    )
  }

  return (
    <div className={classes.root}>
      <Container className={classes.card} maxWidth='lg'>
        <Card className={classes.innerCard}>
          <div className={classes.cardHeaderContainer}>
            <Typography variant='h5' className={classes.cardHeader}>
              Chat
            </Typography>
          </div>
          <CardContent className={classes.cardContent}>
            <List className={classes.leftMenu}>
              {/* manually sorting as firestore doesn't order chats without refreshing */}
              {chats
                ?.slice()
                .sort(
                  (a, b) => b.latestMessage.createdAt?.seconds - a.latestMessage.createdAt?.seconds,
                )
                .map((chat) => (
                  <ChatListItem
                    key={chat.id}
                    active={chat.id === currentChat?.id}
                    chat={chat}
                    onClick={() => {
                      setCurrentChat(chat)
                      onRead(chat)
                    }}
                  />
                ))}
            </List>
            <div className={classes.chatContainer}>
              {currentChat && (
                <ChatContainer chat={currentChat} onRead={() => onRead(currentChat)} />
              )}
            </div>
          </CardContent>
        </Card>
      </Container>
    </div>
  )
}
export default compose(UserIsAuthenticated(ChatPage))
