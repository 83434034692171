export default (theme) => ({
  root: {
    marginTop: 30,
    marginLeft: 260,
  },
  cardContent: {
    padding: theme.spacing(5),
    paddingBottom: 0,
  },
  heading: {
    marginTop: 0,
    marginBottom: 20,
    fontWeight: 'bold',
    color: '#32305D',
  },
  inputContainer: {
    marginTop: 15,
  },
  container: {
    margin: theme.spacing(4, 0),
  },
  addContentButton: {
    ...theme.elevation,
    width: '210px',
    height: '70px',
    marginRight: theme.spacing(4),
    '& .MuiButton-label': {
      justifyContent: 'center',
    },
    '&:hover': {
      ...theme.elevation,
    },
  },
  selectMenu: {
    boxShadow: 0,
    border: `2px solid ${theme.palette.primary.main}`,
  },
  saveButton: {
    backgroundColor: theme.palette.green.main,
    color: '#FFFFFF',
    ...theme.elevation,
    '&:hover': {
      color: '#FFFFFF',
      backgroundColor: theme.palette.green.hover,
      ...theme.elevation,
    },
  },
  deleteButton: {
    backgroundColor: 'white',
    color: theme.palette.red.main,
    borderColor: theme.palette.red.main,
    border: `1px solid ${theme.palette.red.main}`,
    '&:hover': {
      backgroundColor: 'white',
    },
  },
  secondaryButton: {
    backgroundColor: 'white',
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
    '&:hover': {
      backgroundColor: 'white',
    },
  },
  divider: {
    marginTop: 40,
  },
  actions: {
    padding: theme.spacing(5),
    justifyContent: 'space-between',
  },
})
