import React from 'react'
import { Checkbox, Grid } from '@material-ui/core'
import {
  YouTube as VideoIcon,
  Image as ImageIcon,
  SettingsInputAntenna as PodcastIcon,
} from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'

const useStyle = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '62px',
  },
  icon: {
    marginRight: theme.spacing(2),
  },
  checkbox: {
    '& > span > svg': {
      width: '32px',
      height: '32px',
    },
  },
}))

function PostListItem({ title, mediaType, toggle, added }) {
  const classes = useStyle()

  const ContentIcon = () => {
    if (mediaType === 'photos') return <ImageIcon className={classes.icon} />
    if (mediaType === 'video') return <VideoIcon className={classes.icon} />
    if (mediaType === 'audio') return <PodcastIcon className={classes.icon} />
    return null
  }

  return (
    <Grid container className={classes.root} align-items='center'>
      <Grid container item xs={5} alignItems='center'>
        {title}
      </Grid>
      <Grid container item xs style={{ textTransform: 'capitalize' }} alignItems='center'>
        <ContentIcon />
        {mediaType}
      </Grid>
      <Grid container item xs={2} justifyContent='center' alignItems='center'>
        <Checkbox onChange={toggle} checked={added} color='primary' className={classes.checkbox} />
      </Grid>
    </Grid>
  )
}

export default PostListItem
