import React from 'react'
import { isLoaded, useFirestoreConnect, useFirestore } from 'react-redux-firebase'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import ChatInput from './ChatInput'
import ScrollView from 'react-inverted-scrollview/lib'
import CircularProgress from '@material-ui/core/CircularProgress'
import ChatMessage from './ChatMessage'
import * as moment from 'moment'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
  },
  chatContainer: {
    width: '100% !important',
    height: '100% !important',
    paddingTop: theme.spacing(3),
  },
  innerChatContainer: {
    width: '100%',
    maxWidth: 600,
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
  },
  loadingContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  daySeparator: {
    fontSize: 12,
    textAlign: 'center',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    color: '#AAAAAA',
    margin: 20,
  },
}))

const ChatContainer = ({ chat, onRead }) => {
  const classes = useStyles()
  const storeAs = `chatMessages${chat.id}`

  useFirestoreConnect({
    collection: 'chats',
    doc: chat.id,
    subcollections: [
      {
        collection: 'messages',
      },
    ],
    storeAs: storeAs,
    orderBy: 'createdAt',
  })

  const firestore = useFirestore()

  const messages = useSelector((state) => state.firestore.ordered[storeAs])
  const userId = useSelector((state) => state.firebase.auth.uid)

  const sendMessage = async (input) => {
    await firestore.collection('chats').doc(chat.id).collection('messages').add({
      body: input,
      userId: userId,
      createdAt: firestore.FieldValue.serverTimestamp(),
      organizationId: chat.organizationId,
    })
    onRead()
  }

  if (!isLoaded(messages)) {
    return (
      <div className={classes.root}>
        <div className={classes.loadingContainer}>
          <CircularProgress disableShrink mode='indeterminate' />
        </div>
      </div>
    )
  }

  return (
    <div className={classes.root}>
      <ScrollView
        className={classes.chatContainer}
        onScroll={(info) => info.scrollBottom === 0 && onRead()}
      >
        <div className={classes.innerChatContainer}>
          {messages.map((message, index) => {
            // Grouping is done by checking the next element - daySeparator is done by checking the previous
            const nextMessage = index + 1 < messages.length && messages[index + 1]
            const prevMessage = index > 0 && messages[index - 1]
            // Grouping
            const isGrouped =
              nextMessage?.userId === message.userId &&
              nextMessage?.organizationId === message.organizationId
            // Day Separator (boolean)
            let messageDate, prevMessageDate
            let daySeparator = index === 0
            if (message?.createdAt) {
              messageDate = moment.unix(message.createdAt.seconds)
              if (prevMessage?.createdAt) {
                prevMessageDate = moment.unix(prevMessage.createdAt.seconds)
                daySeparator = !messageDate.isSame(prevMessageDate, 'day')
              }
            }

            return (
              <React.Fragment key={index}>
                {daySeparator && messageDate && (
                  <Typography className={classes.daySeparator}>
                    {messageDate.format('DD MMM YYYY')}
                  </Typography>
                )}
                <ChatMessage message={message} grouped={isGrouped} />
              </React.Fragment>
            )
          })}
        </div>
      </ScrollView>
      {!chat.blockedBy ? (
        <ChatInput organizationId={chat.organizationId} onSend={sendMessage} />
      ) : (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            height: 80,
            paddingTop: 20,
            color: '#707070',
            fontWeight: 'bold',
            borderTop: '1px solid #EEEEEE',
          }}
        >
          This chat has been blocked.
        </div>
      )}
    </div>
  )
}
export default ChatContainer
