import React from 'react'
import Navbar from '../containers/Navbar'
import Drawer from '../containers/Drawer'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  container: {
    width: '100vw',
    overflow: 'hidden',
  },
  children: {
    width: '80vw',
  },
}))

function CoreLayout({ children }) {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <Navbar />
      <Drawer />
      {children}
    </div>
  )
}

export default CoreLayout
