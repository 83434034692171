import React, { useEffect, useState } from 'react'
import {
  Button,
  Container,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
  Grid,
  InputLabel,
  FormHelperText,
  TextField,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core'
import { useForm, Controller } from 'react-hook-form'
import { makeStyles } from '@material-ui/core/styles'
import { useFirestore, useFirestoreConnect, isLoaded } from 'react-redux-firebase'
import { useSelector } from 'react-redux'
import LoadingSpinner from '../../../../../components/LoadingSpinner'
import { useParams } from 'react-router-dom'
import * as moment from 'moment'
import styles from './RoomBookingPage.styles'

const useStyles = makeStyles(styles)

function RoomBookingPage() {
  const classes = useStyles()
  const firestore = useFirestore()
  const { organizationId, eventId, bookingId } = useParams()
  const [startIndex, setStartIndex] = useState(0)
  const [declineModalOpen, setDeclineModalOpen] = useState(false)
  const [acceptModalOpen, setAcceptModalOpen] = useState(false)
  const [formData, setFormData] = useState(null)

  const bookingStoreAs = `booking${bookingId}`
  const eventStoreAs = `event${eventId}`

  // Attach organizations listener
  useFirestoreConnect([
    {
      collection: 'organizations',
      doc: organizationId,
    },
  ])

  const organizations = useSelector((state) => state.firestore.data.organizations)

  // Attach event listener
  useFirestoreConnect([
    {
      collection: 'organizations',
      doc: organizationId,
      subcollections: [
        {
          collection: 'events',
          doc: eventId,
        },
      ],
      storeAs: eventStoreAs,
    },
  ])

  const event = useSelector((state) => state.firestore.data[eventStoreAs])

  // Attach booking listener
  useFirestoreConnect([
    {
      collection: 'organizations',
      doc: organizationId,
      subcollections: [
        {
          collection: 'events',
          doc: eventId,
          subcollections: [
            {
              collection: 'bookings',
              doc: bookingId,
            },
          ],
        },
      ],
      storeAs: bookingStoreAs,
    },
  ])

  const booking = useSelector((state) => state.firestore.data[bookingStoreAs])

  const { control, handleSubmit, errors, watch, setValue } = useForm({
    defaultValues: {
      startTime: '',
      endTime: '',
      room: '',
    },
  })

  const {
    control: controlDecline,
    errors: errorsDecline,
    handleSubmit: handleSubmitDecline,
  } = useForm({
    defaultValues: {
      declineReason: '',
    },
  })

  const startTimes = [
    '8:00',
    '8:55',
    '9:50',
    '10:45',
    '11:40',
    '12:35',
    '13:30',
    '14:25',
    '15:20',
    '16:15',
    '17:10',
    '18:05',
    '19:00',
    '19:55',
    '20:50',
    '21:45',
  ]
  const endTimes = [
    '8:45',
    '9:40',
    '10:35',
    '11:30',
    '12:25',
    '13:20',
    '14:15',
    '15:10',
    '16:05',
    '17:00',
    '17:55',
    '18:50',
    '19:45',
    '20:40',
    '21:35',
    '22:20',
  ]

  const startTime = watch('startTime')
  const endTime = watch('endTime')

  // Let's always set index so we only show future endTimes
  useEffect(() => {
    if (startTime !== '') {
      const index = startTimes.indexOf(startTime)
      setStartIndex(index === -1 ? 0 : index)
      if (index > endTimes.indexOf(endTime)) {
        setValue('endTime', endTimes[index])
      }
    }
  }, [startTime, startTimes, endTimes, endTime, setValue])

  useEffect(() => {
    if (booking) {
      setValue('startTime', booking.startTime)
      setValue('endTime', booking.endTime)
    }
  }, [booking, setValue])

  if (!isLoaded(booking) || !isLoaded(event) || !isLoaded(organizations)) return <LoadingSpinner />
  const organization = organizations[organizationId]

  const onSubmit = (data) => {
    setFormData(data)
    setAcceptModalOpen(true)
  }

  const onSubmitDecline = (data) => {
    firestore
      .collection('organizations')
      .doc(organizationId)
      .collection('events')
      .doc(eventId)
      .collection('bookings')
      .doc(bookingId)
      .update({
        response: {
          status: 'rejected',
          message: data.declineReason,
        },
      })
      .then(() => {
        setDeclineModalOpen(false)
      })
  }

  const confirmBooking = () => {
    firestore
      .collection('organizations')
      .doc(organizationId)
      .collection('events')
      .doc(eventId)
      .collection('bookings')
      .doc(bookingId)
      .update({
        response: {
          status: 'accepted',
          room: formData.room,
        },
      })
      .then(() => {
        setAcceptModalOpen(false)
      })
  }

  const DeclineModal = () => (
    <Dialog open={declineModalOpen} className={classes.modal}>
      <form onSubmit={handleSubmitDecline(onSubmitDecline)}>
        <DialogTitle>Decline booking</DialogTitle>
        <DialogContent>
          <Typography gutterBottom>
            You are declining the booking request. Please provide a reason below.
          </Typography>
          <Controller
            as={
              <TextField
                variant='outlined'
                placeholder='Please provide a reason here'
                fullWidth
                multiline
                error={errorsDecline.declineReason !== undefined}
                helperText={errorsDecline?.declineReason?.message || ''}
                minRows={9}
              />
            }
            control={controlDecline}
            name='declineReason'
          />
          <Divider className={classes.divider} />
          <Grid className={classes.item}>
            <Typography className={classes.bold}>Student organization</Typography>
            <Typography>{organization.name}</Typography>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container direction='row' justifyContent='space-between' alignItems='center'>
            <Button
              onClick={() => setDeclineModalOpen(false)}
              className={classes.button}
              variant='outlined'
            >
              Cancel
            </Button>
            <Button
              type='submit'
              className={`${classes.button} ${classes.declineButton}`}
              variant='contained'
            >
              Decline booking request
            </Button>
          </Grid>
        </DialogActions>
      </form>
    </Dialog>
  )

  const AcceptModal = () => (
    <Dialog open={acceptModalOpen} className={classes.modal}>
      <DialogTitle>Confirm booking</DialogTitle>
      <DialogContent>
        <Grid className={classes.item}>
          <Typography>Please confirm that the details below are correct</Typography>
        </Grid>

        <Grid className={classes.item}>
          <Typography className={classes.bold}>Room</Typography>
          <Typography>{formData?.room || ''}</Typography>
        </Grid>

        <Divider className={classes.divider} />

        <Grid className={classes.item}>
          <Typography className={classes.bold}>Student organization</Typography>
          <Typography>{organization.name}</Typography>
        </Grid>

        <Grid className={classes.item}>
          <Typography className={classes.bold}>Event title</Typography>
          <Typography>{event.name}</Typography>
        </Grid>

        <Grid className={classes.item}>
          <Typography className={classes.bold}>Event date</Typography>
          <Typography>{moment(event.timeBegin.seconds * 1000).format('D MMMM, YYYY')}</Typography>
        </Grid>

        <Grid container spacing={3} className={classes.item}>
          <Grid item xs={6}>
            <Typography className={classes.bold}>Booking start time</Typography>
            <Typography>{formData?.startTime || ''}</Typography>
          </Grid>

          <Grid item xs={6}>
            <Typography className={classes.bold}>Booking end time</Typography>
            <Typography>{formData?.endTime || ''}</Typography>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Grid container direction='row' justifyContent='space-between' alignItems='center'>
          <Button
            onClick={() => setAcceptModalOpen(false)}
            className={classes.button}
            variant='outlined'
          >
            Cancel
          </Button>
          <Button
            onClick={confirmBooking}
            className={`${classes.button} ${classes.acceptButton}`}
            color='primary'
            variant='contained'
          >
            Confirm booking
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  )

  if (booking.response.status === 'rejected')
    return (
      <Container maxWidth='sm' className={classes.container}>
        <Typography className={classes.bold}>
          You've succesfully declined the room booking. You may now close this window.
        </Typography>
      </Container>
    )
  if (booking.response.status === 'canceled')
    return (
      <Container maxWidth='sm' className={classes.container}>
        <Typography className={classes.bold}>
          This room booking request has been preemptively canceled by the organization admin. You
          may close this window.
        </Typography>
      </Container>
    )
  if (booking.response.status === 'accepted')
    return (
      <Container maxWidth='sm' className={classes.container}>
        <Typography className={classes.bold}>
          You've succesfully accepted the room booking. You may now close this window.
        </Typography>
      </Container>
    )

  return (
    <Container maxWidth='sm' className={classes.container}>
      <Card className={classes.root}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <CardHeader title='Room booking request' />
          <CardContent>
            <Grid className={classes.item}>
              <Typography className={classes.bold}>Student organization</Typography>
              <Typography>{organization.name}</Typography>
            </Grid>

            <Grid className={classes.item}>
              <Typography className={classes.bold}>Event title</Typography>
              <Typography>{event.name}</Typography>
            </Grid>

            <Grid className={classes.item}>
              <Typography className={classes.bold}>Event description</Typography>
              <Typography>{event.description}</Typography>
            </Grid>

            <Grid container spacing={3} className={classes.item}>
              <Grid item xs={6}>
                <Typography className={classes.bold}>Event date</Typography>
                <Typography>
                  {moment(event.timeBegin.seconds * 1000).format('D MMMM, YYYY')}
                </Typography>
              </Grid>

              <Grid item xs={6}>
                <Typography className={classes.bold}>Week</Typography>
                <Typography>{moment(event.timeBegin.seconds * 1000).format('W')}</Typography>
              </Grid>
            </Grid>

            <Grid className={classes.item}>
              <Typography className={classes.bold}>Preferred building</Typography>
              <Typography>{booking.building}</Typography>
            </Grid>

            <Grid className={classes.item}>
              <Typography className={classes.bold}>
                Maximum expected number of participants
              </Typography>
              <Typography>{booking.occupants}</Typography>
            </Grid>

            <Grid container spacing={3} className={classes.item}>
              <Grid item xs={6}>
                <InputLabel id='startTimeLabel' shrink htmlFor='startTime'>
                  Booking start time
                </InputLabel>
                <Controller
                  rules={{ required: 'This field is required' }}
                  as={
                    <Select
                      labelId='startTimeLabel'
                      id='startTime'
                      variant='outlined'
                      fullWidth
                      error={errors.startTime !== undefined}
                    >
                      {startTimes.map((time) => (
                        <MenuItem key={time} value={time}>
                          {time}
                        </MenuItem>
                      ))}
                    </Select>
                  }
                  control={control}
                  name='startTime'
                />
                <FormHelperText className={classes.errorText}>
                  {errors?.startTime?.message || ''}
                </FormHelperText>
              </Grid>

              <Grid item xs={6}>
                <InputLabel id='endTimeLabel' shrink htmlFor='endTime'>
                  Booking end time
                </InputLabel>
                <Controller
                  rules={{ required: 'This field is required' }}
                  as={
                    <Select
                      labelId='endTimeLabel'
                      id='endTime'
                      variant='outlined'
                      fullWidth
                      error={errors.endTime !== undefined}
                    >
                      {endTimes.slice(startIndex).map((time) => (
                        <MenuItem key={time} value={time}>
                          {time}
                        </MenuItem>
                      ))}
                    </Select>
                  }
                  control={control}
                  name='endTime'
                />
                <FormHelperText className={classes.errorText}>
                  {errors?.endTime?.message || ''}
                </FormHelperText>
              </Grid>
            </Grid>

            <Grid className={classes.item}>
              <Controller
                rules={{ required: 'This field is required' }}
                as={
                  <TextField
                    label='Room'
                    id='room'
                    className={classes.textField}
                    variant='outlined'
                    fullWidth
                    error={errors.room !== undefined}
                    helperText={errors?.room?.message || ''}
                  />
                }
                name='room'
                control={control}
              />
            </Grid>
          </CardContent>
          <CardActions>
            <Grid container direction='row' justifyContent='space-between' alignItems='center'>
              <Button
                onClick={() => setDeclineModalOpen(true)}
                className={classes.declineButtonOutlined}
                variant='outlined'
                size='small'
              >
                Decline request
              </Button>
              <Button
                type='submit'
                className={classes.acceptButton}
                variant='contained'
                size='small'
              >
                Assign room to {organization.name}
              </Button>
            </Grid>
          </CardActions>
        </form>
        <DeclineModal />
        <AcceptModal />
      </Card>
    </Container>
  )
}

export default RoomBookingPage
