export default (theme) => ({
  root: {
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(4),
    paddingRight: theme.spacing(5),
    marginLeft: 260,
    width: 'calc(100vw - 220px)',
  },
  volunteerListContainer: {
    maxHeight: 520,
    borderBottom: '2px solid #EEEEEE',
  },
  paddingRight: {
    paddingRight: 16,
  },
  heading: {
    marginTop: 0,
    marginBottom: 0,
    fontWeight: 'bold',
    color: '#32305D',
  },
  dot: {
    height: 10,
    width: 10,
    borderRadius: '50%',
    margin: '12px 0 0 24px',
  },
  green: {
    backgroundColor: '#46AF46',
  },
  red: {
    backgroundColor: 'red',
  },
  status: {
    fontSize: 14,
    margin: '8px',
  },
  card: {
    marginTop: theme.spacing(3),
    marginRight: theme.spacing(3),
  },
  cardHeader: {
    '& .MuiCardHeader-action': {
      marginTop: 0,
      marginRight: 0,
    },
    '& .MuiCardHeader-title': {
      display: 'flex',
    },
  },
  headTableCell: {
    fontWeight: 'bold',
    border: 0,
  },
  firstCell: {
    width: 80,
  },
  lastCell: {
    width: 260,
  },
  showMoreContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  showMore: {
    marginTop: 24,
  },
  showMoreLabel: {
    justifyContent: 'center',
  },
})
