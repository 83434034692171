export function getImageDimensions(url, callback) {
  const img = document.createElement('img')
  img.onload = function () {
    callback(this.width, this.height)
  }
  img.src = url
}

export function checkImageDimensionAndDisplayInfoMessage(file, snackbar) {
  getImageDimensions(URL.createObjectURL(file), (width, height) => {
    const normalizedAspectRatio = Math.abs(width / height - 16 / 9)
    if (normalizedAspectRatio > 0.05) {
      snackbar.showMessage(
        'The recommended format for photos is 16:9 (e.g. 1280x720px). Detected another format. Your image might be shown incorrectly.',
      )
    }
  })
}

export const getResizedDownloadUrl = async (firebase, originalPath) =>
  new Promise(async (resolve, reject) => {
    const i = originalPath.lastIndexOf('.')
    const resizedImagePath = originalPath.slice(0, i) + '_600x600' + originalPath.slice(i)

    let resizedUrl = ''
    let maxTries = 20
    while (!resizedUrl && maxTries > 0) {
      // Resized image will not be there immediately, so we'll wait 1 second and try
      await new Promise((resolve) => setTimeout(resolve, 1000))
      try {
        resizedUrl = await firebase.storage().ref(resizedImagePath).getDownloadURL()
      } catch (e) {
        console.log(e)
      }
      maxTries--
    }

    if (!resizedUrl) reject('Resized image not found')
    resolve(resizedUrl)
  })
