import React, { useState } from 'react'
import { IconButton, Grid, Typography } from '@material-ui/core'
import {
  Delete as DeleteIcon,
  KeyboardArrowUp as UpIcon,
  KeyboardArrowDown as DownIcon,
} from '@material-ui/icons'
import LoadingSpinner from './LoadingSpinner'
import DeleteContentDialog from './DeleteContentDialog'
import { makeStyles } from '@material-ui/core/styles'

const useStyle = makeStyles((theme) => ({
  root: {
    minWidth: '100%',
    height: '120px',
    margin: theme.spacing(2, 0),
  },
  moveButtons: {
    width: '50px',
  },
  imgContainer: {
    position: 'relative',
    maxWidth: '200px',
    maxHeight: '120px',
    borderRadius: '5px',
    overflow: 'hidden',
    marginRight: theme.spacing(4),
  },
  img: {
    objectFit: 'cover',
    maxWidth: '200px',
  },
  placeholder: {
    width: '100%',
    height: '100%',
    backgroundColor: '#EEEEEE',
  },
  name: {
    fontWeight: 'bold',
  },
  deleteIcon: {
    height: '26px',
    width: '26px',
  },
}))

function PhotoPreview({ src, desc = '', move, deleteFunc, arrayLength, i }) {
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const classes = useStyle()
  return (
    <>
      <DeleteContentDialog
        open={deleteDialogOpen}
        type='photo'
        handleAccept={deleteFunc}
        handleCancel={() => setDeleteDialogOpen(false)}
      />
      <Grid container item className={classes.root}>
        <Grid container className={classes.moveButtons} direction='column' justifyContent='center'>
          {src && (
            <>
              <IconButton onClick={() => move(i, i - 1)} disabled={i === 0} disableRipple>
                <UpIcon />
              </IconButton>
              <IconButton
                onClick={() => move(i, i + 1)}
                disabled={i === arrayLength - 1}
                disableRipple
              >
                <DownIcon />
              </IconButton>
            </>
          )}
        </Grid>
        <Grid container item className={classes.imgContainer}>
          {src ? (
            <img src={src} className={classes.img} alt='media' />
          ) : (
            <Grid
              container
              justifyContent='center'
              alignContent='center'
              className={classes.placeholder}
            >
              <LoadingSpinner />
            </Grid>
          )}
        </Grid>
        <Grid container item xs justifyContent='space-between' alignItems='center'>
          <Grid item>
            <Typography className={classes.name}>{desc}</Typography>
          </Grid>
          {src && (
            <Grid item>
              <IconButton onClick={() => setDeleteDialogOpen(true)}>
                <DeleteIcon className={classes.deleteIcon} />
              </IconButton>
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  )
}

export default PhotoPreview
