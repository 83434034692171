import React from 'react'
import { isEmpty, isLoaded, useFirestoreConnect } from 'react-redux-firebase'
import { useHistory, Redirect } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import _ from 'lodash'
import {
  Button,
  Container,
  ImageList as GridList,
  ImageListItem as GridListTile,
  ImageListItemBar as GridListTileBar,
} from '@material-ui/core'
import { useSelector } from 'react-redux'
import { EDIT_ORGANIZATION_PATH, ORGANIZATION_PATH } from '../../constants/paths'
import LoadingSpinner from '../../components/LoadingSpinner'
import styles from './OrganizationsPage.styles'
import { UserIsAuthenticated } from '../../utils/router'
import { compose } from 'redux'

const useStyles = makeStyles(styles)

function useOrganizations() {
  const history = useHistory()

  // Attach organizations listener
  useFirestoreConnect([{ collection: 'organizations' }])
  const organizationsState = useSelector((state) => state.firestore.ordered.organizations)
  return { organizationsState, history }
}

function OrganizationsPage() {
  const classes = useStyles()
  const { organizationsState, history } = useOrganizations()

  const profile = useSelector((state) => state.firebase.profile)

  // Show spinner while projects & profile are loading
  if (!isLoaded(organizationsState) || !isLoaded(profile)) {
    return <LoadingSpinner />
  }

  // If owner, admin or volunteer, show org
  const organizationIds = _.keys(_.pickBy(profile?.organizations || {}, { owner: true }))
  organizationIds.push(
    ..._.keys(_.pickBy(profile?.organizations || {}, { volunteerStatus: 'admin' })),
  )
  organizationIds.push(
    ..._.keys(_.pickBy(profile?.organizations || {}, { volunteerStatus: 'volunteer' })),
  )

  console.log({ profile })
  const organizations = _.filter(organizationsState, (org) =>
    !profile.isSuperAdmin ? _.includes(organizationIds, org.id) : org,
  )

  return (
    <div>
      {isLoaded(organizations) && organizations.length === 1 ? (
        <Redirect to={ORGANIZATION_PATH(organizations[0].id)} />
      ) : (
        <Container maxWidth='md'>
          <GridList cols={3} gap={3}>
            <GridListTile key='Subheader' cols={3} style={{ height: 'auto' }}>
              <div className={classes.header}>
                Organizations{' '}
                {profile.isSuperAdmin && (
                  <Button
                    size='small'
                    variant='outlined'
                    onClick={() => {
                      history.push(EDIT_ORGANIZATION_PATH('new'))
                    }}
                  >
                    Create Organization
                  </Button>
                )}
              </div>
            </GridListTile>

            {!isEmpty(organizations) &&
              organizations.map((organization) => (
                <GridListTile
                  key={organization.id}
                  cols={1}
                  className={classes.tile}
                  onClick={() => history.push(ORGANIZATION_PATH(organization.id))}
                >
                  <img src={organization.logoUrl} alt={organization.name} />
                  <GridListTileBar className={classes.titleBar} title={organization.name} />
                </GridListTile>
              ))}
          </GridList>
        </Container>
      )}
    </div>
  )
}
export default compose(UserIsAuthenticated(OrganizationsPage))
