import React from 'react'
import { Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyle = makeStyles(() => ({
  root: {
    minWidth: '52px',
  },
}))

function ListItemButton({ onClick, label, variant = 'outlined' }) {
  const classes = useStyle()

  return (
    <Button className={classes.root} color='primary' variant={variant} onClick={onClick}>
      {label}
    </Button>
  )
}

export default ListItemButton
