import React from 'react'
import { Switch, Route } from 'react-router-dom'
import CoreLayout from '../layouts/CoreLayout'
import HomePage from './Home/HomePage'
import LoginPage from './Login/LoginPage'
import NotFoundPage from './NotFound/NotFoundPage'
import OrganizationsPage from './Organizations/OrganizationsPage'
import Dashboard from './Organizations/Dashboard/Dashboard'
import EditOrganizationPage from './Organizations/EditOrganization/EditOrganizationPage'
import EventsPage from './Organizations/Events/EventsPage'
import EventPage from './Organizations/Events/Event/EventPage'
import RoomBookingPage from './Organizations/Events/Event/RoomBooking/RoomBookingPage'
import PostsPage from './Organizations/Posts/PostsPage'
import PostPage from './Organizations/Posts/Post/PostPage'
import ChatPage from './Organizations/Chat/ChatPage'
import CollectionsPage from './Organizations/Collections/CollectionsPage'
import CollectionPage from './Organizations/Collections/Collection/CollectionPage'
import VolunteersPage from './Organizations/Volunteers/VolunteersPage'
import {
  LOGIN_PATH,
  ORGANIZATIONS_PATH,
  ORGANIZATION_PATH,
  EDIT_ORGANIZATION_PATH,
  EVENTS_PATH,
  EVENT_PATH,
  ROOMBOOKING_PATH,
  POSTS_PATH,
  CHAT_PATH,
  POST_PATH,
  COLLECTIONS_PATH,
  COLLECTION_PATH,
  VOLUNTEERS_PATH,
  VOLUNTEER_JOBS_PATH,
  VOLUNTEER_JOB_PATH,
} from '../constants/paths'
import VolunteerJobPage from './Organizations/VolunteerJobs/VolunteerJob/VolunteerJobPage'
import VolunteerJobsPage from './Organizations/VolunteerJobs/VolunteerJobsPage'

export default function createRoutes() {
  return (
    <CoreLayout>
      <Switch>
        <Route exact path='/' component={HomePage} />
        <Route exact path={LOGIN_PATH} component={LoginPage} />
        <Route exact path={ORGANIZATIONS_PATH} component={OrganizationsPage} />
        <Route exact path={ORGANIZATION_PATH(':organizationId')} component={Dashboard} />
        <Route
          exact
          path={EDIT_ORGANIZATION_PATH(':organizationId')}
          component={EditOrganizationPage}
        />
        <Route exact path={EVENTS_PATH(':organizationId')} component={EventsPage} />
        <Route exact path={EVENT_PATH(':organizationId', ':eventId')} component={EventPage} />
        <Route
          exact
          path={ROOMBOOKING_PATH(':organizationId', ':eventId', ':bookingId')}
          component={RoomBookingPage}
        />
        <Route exact path={POSTS_PATH(':organizationId')} component={PostsPage} />
        <Route exact path={POST_PATH(':organizationId', ':postId')} component={PostPage} />
        <Route exact path={VOLUNTEER_JOBS_PATH(':organizationId')} component={VolunteerJobsPage} />
        <Route
          exact
          path={VOLUNTEER_JOB_PATH(':organizationId', ':volunteerJobId')}
          component={VolunteerJobPage}
        />
        <Route exact path={COLLECTIONS_PATH(':organizationId')} component={CollectionsPage} />
        <Route
          exact
          path={COLLECTION_PATH(':organizationId', ':collectionId')}
          component={CollectionPage}
        />
        <Route exact path={VOLUNTEERS_PATH(':organizationId')} component={VolunteersPage} />
        <Route exact path={CHAT_PATH(':organizationId', ':chatUserId')} component={ChatPage} />
        <Route component={NotFoundPage} />
      </Switch>
    </CoreLayout>
  )
}
