export default () => ({
  container: {
    marginTop: 40,
  },
  root: {
    padding: 40,
  },
  bold: {
    fontWeight: 'bold',
  },
  item: {
    paddingBottom: 20,
  },
  divider: {
    marginBottom: 20,
  },
  modal: {
    padding: 30,
  },
  acceptButton: {
    backgroundColor: '#07936B',
    color: '#FFFFFF',
    '&:hover': {
      color: '#FFFFFF',
      backgroundColor: '#07936B',
    },
  },
  declineButton: {
    backgroundColor: '#B10024',
    color: '#FFFFFF',
    '&:hover': {
      color: '#FFFFFF',
      backgroundColor: '#B10024',
    },
  },
  declineButtonOutlined: {
    color: '#B10024',
    borderColor: '#B10024',
  },
})
