import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { isLoaded } from 'react-redux-firebase'

export const usePrivileged = (organizationId) => {
  const profile = useSelector((state) => state.firebase.profile)
  const [priviliged, setPriviliged] = useState(false)

  useEffect(() => {
    if (isLoaded(profile)) {
      const newPrivileged =
        profile?.isSuperAdmin ||
        profile?.organizations?.[organizationId]?.volunteerStatus === 'admin' ||
        profile?.organizations?.[organizationId].owner
      setPriviliged(newPrivileged)
    }
  }, [profile, setPriviliged])
  return priviliged
}
