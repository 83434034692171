import {
  Button,
  Card,
  CardActions,
  CardContent,
  Container,
  Divider,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Switch,
  TextField,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Backspace, Description as DescriptionIcon, Save as SaveIcon } from '@material-ui/icons'
import DateTimePicker from 'components/DateTimePicker'
import PinnedDialog from 'components/PinnedDialog'
import { useSnackbar } from 'material-ui-snackbar-provider'
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { isLoaded, useFirebase, useFirestore, useFirestoreConnect } from 'react-redux-firebase'
import { useHistory, useParams } from 'react-router-dom'
import { compose } from 'redux'
import LoadingSpinner from '../../../../components/LoadingSpinner'
import { VOLUNTEER_JOBS_PATH } from '../../../../constants/paths'
import Drawer from '../../../../containers/Drawer'
import { UserIsAuthenticated } from '../../../../utils/router'
import styles from './VolunteerJobPage.styles'
import { onOrganizationFormSubmit } from '../../../../utils/form'

const COLLECTION_NAME = 'volunteer-jobs'

function VolunteerJobPage() {
  const { organizationId, volunteerJobId } = useParams()
  const snackbar = useSnackbar()
  const firestore = useFirestore()
  const firebase = useFirebase()
  const history = useHistory()
  const volunteerJobStoreAs = `organizationvolunteerJob${organizationId}${volunteerJobId}`

  const [pinnedDialogOpen, setPinnedDialogOpen] = useState(false)
  const [pinnedTitle, setPinnedTitle] = useState('')

  const [loading, setLoading] = useState(false)
  const [deadline, setDeadline] = useState(null)

  const useStyles = makeStyles(styles)
  const classes = useStyles()

  useFirestoreConnect([
    {
      collection: 'organizations',
      doc: organizationId,
      subcollections: [
        {
          collection: COLLECTION_NAME,
          doc: volunteerJobId,
        },
      ],
      storeAs: volunteerJobStoreAs,
    },
  ])

  const volunteerJob = useSelector((state) => state.firestore.data[volunteerJobStoreAs])

  const { control, handleSubmit, errors, setValue, reset, watch } = useForm({
    defaultValues: {
      title: '',
      body: '',
      application: {
        method: 'email',
      },
      isPinned: false,
    },
  })
  const applicationType = watch('application.method')

  const isNewPosition = volunteerJobId === 'new'
  const isOpportunityExpired = volunteerJob?.application?.deadline?.toDate() < new Date()
  const jobCanBePublished = volunteerJob?.status !== 'published' || isOpportunityExpired

  useEffect(() => {
    if (volunteerJob && !isNewPosition) {
      reset(volunteerJob)
      volunteerJob.application.deadline && setDeadline(volunteerJob.application.deadline.toDate())
    }
  }, [volunteerJob, volunteerJobId, reset])

  if (loading || !isLoaded(volunteerJob)) {
    return <LoadingSpinner />
  }

  const onSubmit = async (data, status) => {
    setLoading(true)

    if (isNewPosition) {
      firestore
        .collection('organizations')
        .doc(organizationId)
        .get()
        .then((organization) => {
          firestore
            .collection('organizations')
            .doc(organizationId)
            .collection(COLLECTION_NAME)
            .add({
              ...data,
              organization: {
                logoUrl: organization?.data().logoUrl,
                name: organization?.data().name,
                status: organization?.data().status,
              },
              application: {
                ...data.application,
                deadline,
              },
              status,
              createdAt: firebase.firestore.FieldValue.serverTimestamp(),
            })
            .then(() => {
              setLoading(false)
              snackbar.showMessage('Changes successfully saved')
              history.push(VOLUNTEER_JOBS_PATH(organizationId))
            })
            .catch((err) => {
              setLoading(false)
              console.error('Error:', err) // eslint-disable-line no-console
              return Promise.reject(err)
            })
        })
        .catch((err) => {
          setLoading(false)
          console.error('Error:', err) // eslint-disable-line no-console
          return Promise.reject(err)
        })
    } else {
      firestore
        .collection('organizations')
        .doc(organizationId)
        .collection(COLLECTION_NAME)
        .doc(volunteerJobId)
        .update({
          ...data,
          application: {
            ...data.application,
            deadline,
          },
          updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
          status,
        })
        .then(() => {
          setLoading(false)
          snackbar.showMessage('Changes successfully saved')
          history.push(VOLUNTEER_JOBS_PATH(organizationId))
        })
        .catch((err) => {
          setLoading(false)
          console.error('Error:', err) // eslint-disable-line no-console
          return Promise.reject(err)
        })
    }
  }

  const handlePinnedDialogCancel = () => {
    setValue('isPinned', false)
    setPinnedDialogOpen(false)
  }

  const handlePinnedDialogAccept = () => setPinnedDialogOpen(false)

  const handlePinnedState = ([e]) => {
    if (e.target.checked) {
      firestore
        .collection('organizations')
        .doc(organizationId)
        .get()
        .then(function (doc) {
          if (doc.data()?.pinnedContent && doc.data()?.pinnedContent?.id !== volunteerJobId) {
            setPinnedTitle(doc.data().pinnedContent.title)
            setPinnedDialogOpen(true)
          }
        })
    }
    return e.target.checked
  }

  return (
    <>
      <Drawer />
      <PinnedDialog
        open={pinnedDialogOpen}
        handleCancel={handlePinnedDialogCancel}
        handleAccept={handlePinnedDialogAccept}
        title={pinnedTitle}
      />
      <Container className={classes.root} maxWidth='md'>
        <Card className={classes.card}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Controller
              as={<input type='text' />}
              name={`status`}
              defaultValue={'draft'}
              control={control}
              style={{ display: 'none' }}
            />
            <CardContent className={classes.cardContent}>
              <div className={classes.headingRow}>
                <h2 className={classes.heading}>
                  {isNewPosition ? 'Create opportunity' : 'Edit opportunity'}
                </h2>
                {volunteerJob?.status && (
                  <p className={classes.status}>{`(Status: ${
                    isOpportunityExpired ? 'past' : volunteerJob.status
                  })`}</p>
                )}
              </div>

              <Grid container spacing={0} direction='row' justifyContent='flex-start'>
                <Grid className={classes.inputContainer} item xs={12} style={{ marginTop: 30 }}>
                  <Controller
                    rules={{ required: 'This field is required' }}
                    as={
                      <TextField
                        fullWidth
                        label='Opportunity title'
                        type='text'
                        variant='outlined'
                        error={errors.title !== undefined}
                        helperText={errors?.title?.message}
                      />
                    }
                    name='title'
                    control={control}
                  />
                </Grid>

                <Grid className={classes.inputContainer} item xs={12} style={{ marginTop: 30 }}>
                  <Controller
                    as={
                      <TextField
                        fullWidth
                        multiline
                        minRows={9}
                        label='Description'
                        type='text'
                        variant='outlined'
                      />
                    }
                    name='body'
                    control={control}
                  />
                </Grid>

                <Grid item xs={12}>
                  <h4>Pinned</h4>
                  <p>
                    You can pin one post, event, collection or open opportunity to the top of the
                    page.
                  </p>
                  <Controller
                    as={<Switch color='primary' />}
                    name='isPinned'
                    onChange={handlePinnedState}
                    control={control}
                  />
                </Grid>

                <Grid className={classes.inputContainer} item xs={12}>
                  <h4>Deadline</h4>
                  <p>
                    Set a date if the opportunity has a deadline for applications. The post will be
                    hidden after the deadline has passed.
                  </p>
                  <DateTimePicker
                    style={{ width: '100%' }}
                    variant='outlined'
                    label='Choose a date'
                    timeValue={deadline}
                    handleTimeChange={setDeadline}
                    outlined
                  />
                </Grid>
                <Grid className={`${classes.inputContainer} ${classes.mb1}`} item xs={12}>
                  <h4>Application</h4>
                  <p>
                    Enter either a link to an application form or an e-mail address students can
                    contact to apply for this opportunity
                  </p>
                  <Controller
                    rules={{ required: true }}
                    control={control}
                    name='application.method'
                    as={
                      <RadioGroup>
                        <FormControlLabel value='email' control={<Radio />} label='Email' />
                        <FormControlLabel value='website' control={<Radio />} label='Website' />
                      </RadioGroup>
                    }
                  />
                </Grid>
                <Grid className={classes.inputContainer} item xs={12}>
                  {applicationType === 'email' && (
                    <Controller
                      rules={{
                        required: 'This field is required',
                        pattern: {
                          value:
                            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                          message: 'Please enter a valid email',
                        },
                      }}
                      as={
                        <TextField
                          fullWidth
                          placeholder='email@cbs.dk'
                          label='Email'
                          type='email'
                          variant='outlined'
                          error={errors.application?.email !== undefined}
                          helperText={errors?.application?.email?.message}
                        />
                      }
                      name='application.email'
                      control={control}
                    />
                  )}
                  {applicationType === 'website' && (
                    <Controller
                      rules={{
                        required: 'This field is required',
                        pattern: {
                          value:
                            /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/,
                          message: 'Please enter a valid url (start with https://)',
                        },
                      }}
                      as={
                        <TextField
                          fullWidth
                          placeholder='https://website.dk/'
                          label='Website Url'
                          type='url'
                          variant='outlined'
                          error={errors.application?.url !== undefined}
                          helperText={errors?.application?.url?.message}
                        />
                      }
                      name='application.url'
                      control={control}
                    />
                  )}
                </Grid>
              </Grid>

              <Divider className={classes.divider} />
            </CardContent>
            <CardActions className={classes.cardActions}>
              <Button
                onClick={handleSubmit((data) =>
                  onOrganizationFormSubmit({
                    formData: data,
                    saveAsDraft: true,
                    currentEntityStatus: volunteerJob?.status,
                    saveEntity: onSubmit,
                  }),
                )}
                className={classes.secondaryButton}
                variant='outlined'
                disabled={loading}
                size='large'
                endIcon={<SaveIcon />}
              >
                Save changes
              </Button>
              <div />
              <Button
                className={jobCanBePublished ? classes.saveButton : classes.deleteButton}
                variant='contained'
                onClick={handleSubmit((data) =>
                  onOrganizationFormSubmit({
                    formData: data,
                    saveAsDraft: false,
                    currentEntityStatus: volunteerJob?.status,
                    saveEntity: onSubmit,
                    isExpired: isOpportunityExpired,
                    expirationDate: deadline,
                    showExpirationError: () =>
                      snackbar.showMessage('Please move the end of the opportunity.'),
                  }),
                )}
                disabled={loading}
                size='large'
                endIcon={jobCanBePublished ? <DescriptionIcon /> : <Backspace />}
              >
                {jobCanBePublished ? 'Publish' : 'Unpublish'}{' '}
              </Button>
            </CardActions>
          </form>
        </Card>
      </Container>
    </>
  )
}

export default compose(UserIsAuthenticated(VolunteerJobPage))
