import algoliasearch from 'algoliasearch/lite'
import getEnv from './getEnv'

const searchClient = algoliasearch('ZA1EP32V6B', '2f97910d42940f3cab6f2854c5d89eba')

export const getIndexName = (index) => {
  switch (getEnv()) {
    case 'production':
      return 'prod_' + index
    default:
      return 'staging_' + index
  }
}

export const usersIndexName = getIndexName('users')
export const contentIndexName = getIndexName('content')

export default searchClient
