import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { AddCircle as AddCircleIcon } from '@material-ui/icons'
import * as moment from 'moment'
import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { isEmpty, isLoaded, useFirestore, useFirestoreConnect } from 'react-redux-firebase'
import { useHistory, useParams } from 'react-router-dom'
import { compose } from 'redux'
import { usePrivileged } from 'utils/profile'
import ListItemButton from '../../../components/ListItemButton'
import LoadingSpinner from '../../../components/LoadingSpinner'
import { VOLUNTEER_JOB_PATH } from '../../../constants/paths'
import Drawer from '../../../containers/Drawer'
import { UserIsAuthenticated } from '../../../utils/router'
import styles from './VolunteerJobsPage.styles'

function isJobPast(job) {
  return job?.application?.deadline?.toDate() < new Date()
}

function VolunteerJobsPage() {
  const { organizationId } = useParams()
  const firestore = useFirestore()
  const history = useHistory()
  const storeAs = `organizationvolunteerJobs${organizationId}`
  const storeAsOrg = `organization${organizationId}`

  const useStyles = makeStyles(styles)
  const classes = useStyles()

  useFirestoreConnect([
    {
      collection: 'organizations',
      doc: organizationId,
      subcollections: [
        {
          collection: 'volunteer-jobs',
        },
      ],
      storeAs: storeAs,
      orderBy: ['createdAt', 'desc'],
    },
  ])

  useFirestoreConnect([
    {
      collection: 'organizations',
      doc: organizationId,
      storeAs: storeAsOrg,
    },
  ])

  const volunteerJobs = useSelector((state) => state.firestore.ordered[storeAs])
  const auth = useSelector((state) => state.firebase.auth)

  const unpinvolunteerJob = useCallback(
    (volunteerJobId) => {
      firestore.update(
        {
          collection: 'organizations',
          doc: organizationId,
          subcollections: [
            {
              collection: 'volunteer-jobs',
              doc: volunteerJobId,
            },
          ],
        },
        {
          isPinned: false,
        },
      )
    },
    [firestore, organizationId],
  )

  useCallback(
    async (positionId) => {
      const volunteerJob = volunteerJobs.find((position) => position.id === positionId)
      if (volunteerJob) {
        const snap = await firestore
          .collection('organizations')
          .doc(organizationId)
          .collection('volunteer-jobs')
          .where('isPinned', '==', true)
          .limit(1)
          .get()
        const pinnedPosition = snap?.docs[0]

        if (pinnedPosition) {
          unpinvolunteerJob(pinnedPosition.id)
        }

        await firestore
          .collection('organizations')
          .doc(organizationId)
          .collection('volunteer-jobs')
          .doc(positionId)
          .update({
            isPinned: true,
          })
      }
    },
    [firestore, organizationId, volunteerJobs],
  )

  const privileged = usePrivileged(organizationId)

  if (!isLoaded(volunteerJobs)) {
    return <LoadingSpinner />
  }

  const goTovolunteerJob = (volunteerJob) => {
    history.push(VOLUNTEER_JOB_PATH(organizationId, volunteerJob))
  }

  return (
    <>
      <Drawer />
      <Container className={classes.root} maxWidth='md'>
        <Card>
          <CardHeader
            className={classes.cardHeader}
            title={<h4 className={classes.heading}>Open Opportunities</h4>}
            action={
              privileged && (
                <Button
                  color='primary'
                  className={classes.create}
                  variant='contained'
                  endIcon={<AddCircleIcon />}
                  onClick={() => {
                    goTovolunteerJob('new')
                  }}
                >
                  New Open Opportunity
                </Button>
              )
            }
          />
          <CardContent>
            <TableContainer>
              <Table className={classes.table} aria-label='Planned events'>
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.headTableCell}>Title</TableCell>
                    <TableCell className={classes.headTableCell}>Created</TableCell>
                    <TableCell className={classes.headTableCell}>Deadline</TableCell>
                    <TableCell className={classes.headTableCell}>Status</TableCell>
                    {/* <TableCell className={classes.headTableCell} align='right' /> */}
                    <TableCell className={classes.headTableCell} align='right' />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!isEmpty(volunteerJobs) &&
                    volunteerJobs
                      .filter((job) => !isJobPast(job))
                      .map((volunteerJob) => (
                        <TableRow key={volunteerJob.id}>
                          <TableCell className={classes.tableCell} component='th' scope='row'>
                            {volunteerJob.title}
                          </TableCell>
                          <TableCell className={classes.tableCell} component='th' scope='row'>
                            {moment.unix(volunteerJob.createdAt.seconds).format('D MMM, YYYY')}
                          </TableCell>
                          <TableCell className={classes.tableCell} component='th' scope='row'>
                            {volunteerJob.application?.deadline
                              ? moment
                                  .unix(volunteerJob.application?.deadline.seconds)
                                  .format('D MMM, YYYY')
                              : 'No deadline'}
                          </TableCell>
                          <TableCell className={classes.tableCell} component='th' scope='row'>
                            <Chip
                              size='small'
                              className={classes[volunteerJob.status]}
                              label={volunteerJob.status}
                            />
                          </TableCell>
                          {/* <TableCell className={classes.tableCellSmall} align='right'>
                          {privileged && (
                            <ListItemButton
                              variant={volunteerJob.isPinned ? 'outlined' : 'contained'}
                              label={volunteerJob.isPinned ? 'Unpin' : 'Pin'}
                              onClick={() => {
                                if (!volunteerJob.isPinned) {
                                  pinvolunteerJob(volunteerJob.id)
                                } else {
                                  unpinvolunteerJob(volunteerJob.id)
                                }
                              }}
                            />
                          )}
                        </TableCell> */}
                          <TableCell className={classes.tableCellSmall} align='right'>
                            {(privileged ||
                              volunteerJob?.responsibleVolunteers?.includes(auth.uid)) && (
                              <ListItemButton
                                label='Edit'
                                onClick={() => {
                                  goTovolunteerJob(volunteerJob.id)
                                }}
                              />
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
        </Card>

        <Card className={classes.card}>
          <CardHeader
            className={classes.cardHeader}
            title={<h4 className={classes.heading}>Past Open Opportunities</h4>}
          />
          <CardContent>
            <TableContainer>
              <Table className={classes.table} aria-label='Planned events'>
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.headTableCell}>Title</TableCell>
                    <TableCell className={classes.headTableCell}>Created</TableCell>
                    <TableCell className={classes.headTableCell}>Deadline</TableCell>
                    <TableCell className={classes.headTableCell}>Status</TableCell>
                    {/* <TableCell className={classes.headTableCell} align='right' /> */}
                    <TableCell className={classes.headTableCell} align='right' />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!isEmpty(volunteerJobs) &&
                    volunteerJobs
                      .filter((job) => isJobPast(job))
                      .map((volunteerJob) => (
                        <TableRow key={volunteerJob.id}>
                          <TableCell className={classes.tableCell} component='th' scope='row'>
                            {volunteerJob.title}
                          </TableCell>
                          <TableCell className={classes.tableCell} component='th' scope='row'>
                            {moment.unix(volunteerJob.createdAt.seconds).format('D MMM, YYYY')}
                          </TableCell>
                          <TableCell className={classes.tableCell} component='th' scope='row'>
                            {volunteerJob.application?.deadline
                              ? moment
                                  .unix(volunteerJob.application?.deadline.seconds)
                                  .format('D MMM, YYYY')
                              : 'No deadline'}
                          </TableCell>
                          <TableCell className={classes.tableCell} component='th' scope='row'>
                            <Chip size='small' className={classes.past} label='Past' />
                          </TableCell>
                          {/* <TableCell className={classes.tableCellSmall} align='right'>
                          {privileged && (
                            <ListItemButton
                              variant={volunteerJob.isPinned ? 'outlined' : 'contained'}
                              label={volunteerJob.isPinned ? 'Unpin' : 'Pin'}
                              onClick={() => {
                                if (!volunteerJob.isPinned) {
                                  pinvolunteerJob(volunteerJob.id)
                                } else {
                                  unpinvolunteerJob(volunteerJob.id)
                                }
                              }}
                            />
                          )}
                        </TableCell> */}
                          <TableCell className={classes.tableCellSmall} align='right'>
                            {(privileged ||
                              volunteerJob?.responsibleVolunteers?.includes(auth.uid)) && (
                              <ListItemButton
                                label='Edit'
                                onClick={() => {
                                  goTovolunteerJob(volunteerJob.id)
                                }}
                              />
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
        </Card>
      </Container>
    </>
  )
}

export default compose(UserIsAuthenticated(VolunteerJobsPage))
